import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	Row,
	Select,
	Upload,
	notification,
} from "antd";
import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { api } from "../utils/Api";
import axios from "axios";
import dayjs from "dayjs";
import { CustomSpinner } from "../utils/CustomComponents";
import { UploadOutlined } from "@ant-design/icons";

const optionsVehicleType = [
	{
		value: "Pick Up",
		label: "Pick Up",
	},
	{
		value: "Car",
		label: "Car",
	},
];

const optionsVehicleProperty = [
	{
		value: "Owned",
		label: "Owned",
	},
	{
		value: "Leased",
		label: "Leased",
	},
];

const optionsVehicleCompany = [
	{
		value: "57 Logistics",
		label: "57 Logistics",
	},
	{
		value: "Filegonia Materials",
		label: "Filegonia Materials",
	},
	{
		value: "1457 Logistics",
		label: "1457 Logistics",
	},
	{
		value: "57 Concrete of San Benito",
		label: "57 Concrete of San Benito",
	},
	{
		value: "57 Fuels",
		label: "57 Fuels",
	},
];

const optionsDepartmentCompany = [
	{
		value: "Residential",
		label: "Residential",
	},
	{
		value: "Commercial",
		label: "Commercial",
	},
	{
		value: "Office",
		label: "Office",
	},
	{
		value: "Maintenance",
		label: "Maintenance",
	},
	{
		value: "Production",
		label: "Production",
	},
	{
		value: "Corporate",
		label: "Corporate",
	},
];

const NewVehicle = ({ history }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(true);
	const [loadingButton, setLoadingButton] = useState(false);
	const [loanList, setLoanList] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [salesRemoved, setSalesRemoved] = useState(false);
	const [salesAdded, setSalesAdded] = useState(false);
	const [insuranceRemoved, setInsuranceRemoved] = useState(false);
	const [insuranceAdded, setInsuranceAdded] = useState(false);

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getLoans();
	}, []);

	const getLoans = async () => {
		try {
			const url = api + "/loan/list/options";
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.get(url);
			if (data.success) {
				let list = [];
				data.payload.map((item) => {
					list.push({
						label: `${item.owner} - ${item.lender} - ${item.collateral}`,
						value: item._id,
					});
				});
				setLoanList(list);
			} else {
				openNotification("error", "Error", data.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to create vehicle"
			);
			console.error(err.message);
			return null;
		}
	};

	const createVehicle = async (values) => {
		const vehicle = {
			...values,
			insuranceExpiration: values.insuranceExpiration
				? dayjs(values.insuranceExpiration).toDate()
				: undefined,
			sticker: values.sticker ? dayjs(values.sticker).toDate() : undefined,
			platesExpiration: values.platesExpiration
				? dayjs(values.platesExpiration).toDate()
				: undefined,
			vehicleType: "Vehicle",
		};

		delete vehicle.salesFinanceAgreement;
		delete vehicle.insuranceIDCard;

		try {
			const url = api + "/office/vehicle/create";
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.post(url, vehicle);
			if (data.success) {
				if (
					(salesAdded || insuranceAdded) &&
					(!salesRemoved || !insuranceRemoved)
				) {
					addVehicle(values, data.payload._id);
				} else {
					openNotification("success", "Success", data.message);

					setTimeout(() => {
						setLoadingButton(false);
						history.push("/office/vehicle/list");
					}, 2000);
				}
			} else {
				setLoadingButton(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			setLoadingButton(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to create vehicle"
			);
			console.error(err.message);
			return null;
		}
	};

	const addVehicle = async (values, id) => {
		const formData = new FormData();

		if (
			salesAdded &&
			values.salesFinanceAgreement &&
			values.salesFinanceAgreement.length > 0
		) {
			formData.append(
				"salesFinanceAgreement",
				values.salesFinanceAgreement[0].originFileObj
			);
		}

		if (
			insuranceAdded &&
			values.insuranceIDCard &&
			values.insuranceIDCard.length > 0
		) {
			formData.append(
				"insuranceIDCard",
				values.insuranceIDCard[0].originFileObj
			);
		}

		try {
			const url = api + `/office/vehicle/add/images/${id}`;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
			const config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};

			const { data } = await axios.post(url, formData, config);
			if (data.success) {
				openNotification("success", "Success", "Vehicle Created");

				setTimeout(() => {
					setLoadingButton(false);
					history.push("/office/vehicle/list");
				}, 2000);
			} else {
				setLoadingButton(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			setLoadingButton(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to add images vehicle"
			);
			console.error(err.message);
			return null;
		}
	};

	const normFile = (e) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e?.fileList;
	};

	const onChangeSalesFinanceAgreement = ({ file }) => {
		if (file.status === "removed") {
			setSalesRemoved(true);
			setSalesAdded(false);
		}
		if (!file.status) {
			setSalesAdded(true);
			setSalesRemoved(false);
		}
	};

	const onChangeInsurance = ({ file }) => {
		if (file.status === "removed") {
			setInsuranceRemoved(true);
			setInsuranceAdded(false);
		}
		if (!file.status) {
			setInsuranceAdded(true);
			setInsuranceRemoved(false);
		}
	};

	const onFinish = (values) => {
		setLoadingButton(true);
		createVehicle(values);
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				style={{ marginTop: "20px" }}
				initialValues={{
					state: "TX",
				}}
			>
				<Row gutter={16}>
					<Col span={24} sm={12} xl={6}>
						<Form.Item
							label="Vehicle No."
							name="number"
							rules={[
								{ required: true, message: "Vehicle Number is required" },
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12} xl={6}>
						<Form.Item label="Vehicle Type" name="type">
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select type"
								options={optionsVehicleType}
								showSearch
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={6}>
						<Form.Item label="Year" name="year">
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={6}>
						<Form.Item
							label="Make"
							name="make"
							rules={[
								{
									required: true,
									message: "Make is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={6}>
						<Form.Item
							label="Model"
							name="model"
							rules={[
								{
									required: true,
									message: "Model is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12} xl={8}>
						<Form.Item
							label="Vehicle ID Number (VIN)"
							name="vin"
							rules={[
								{
									required: true,
									message: "VIN is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={8}>
						<Form.Item label="License Plates" name="plates">
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={8}>
						<Form.Item label="License Plate State" name="state">
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12} xl={8}>
						<Form.Item
							label="Property"
							name="property"
							rules={[
								{
									required: true,
									message: "Property is required",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select a property"
								options={optionsVehicleProperty}
								showSearch
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={8}>
						<Form.Item label="Sticker / Plates" name="sticker">
							<DatePicker
								format={"MMMM - YYYY"}
								size="large"
								style={{ width: "100%" }}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} xl={8}>
						<Form.Item label="Driver" name="driver">
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item label="Insurance" name="insurance">
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item label="Insurance Expiration" name="insuranceExpiration">
							<DatePicker
								format={"MM/DD/YYYY"}
								size="large"
								style={{ width: "100%" }}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item label="Plates Expiration" name="platesExpiration">
							<DatePicker
								format={"MM/DD/YYYY"}
								size="large"
								style={{ width: "100%" }}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item label="Loan" name="loan">
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select loan"
								options={loanList}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								allowClear
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item label="Company" name="company">
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select a company"
								options={optionsVehicleCompany}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								allowClear
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item label="Department" name="department">
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select a department"
								options={optionsDepartmentCompany}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								allowClear
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={15}>
					<Col span={24} xs={12}>
						<Form.Item
							label="Sales / Finance Agreement"
							name="salesFinanceAgreement"
							valuePropName="fileList"
							getValueFromEvent={normFile}
						>
							<Upload
								beforeUpload={() => false}
								maxCount={1}
								multiple={false}
								accept=".pdf"
								onChange={onChangeSalesFinanceAgreement}
							>
								<Button icon={<UploadOutlined />} size="large">
									Upload
								</Button>
							</Upload>
						</Form.Item>
					</Col>
					<Col span={24} xs={12}>
						<Form.Item
							label="Insurance ID Card"
							name="insuranceIDCard"
							valuePropName="fileList"
							getValueFromEvent={normFile}
						>
							<Upload
								beforeUpload={() => false}
								maxCount={1}
								multiple={false}
								accept=".pdf"
								onChange={onChangeInsurance}
							>
								<Button icon={<UploadOutlined />} size="large">
									Upload
								</Button>
							</Upload>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col span={24} sm={12} md={4}>
						<Button
							type="primary"
							htmlType="submit"
							loading={loadingButton}
							size="large"
							style={{
								width: "100%",
							}}
						>
							Create
						</Button>
					</Col>
				</Row>
			</Form>
		);
	};

	return (
		<Layout title="New Vehicle" description="Create new vehicle" type="medium">
			{contextHolder}
			{loading ? <CustomSpinner /> : <CustomForm />}
		</Layout>
	);
};

export default NewVehicle;
