import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { createForecast } from "../../actions/forecast";
import DatePicker from "react-datepicker";
import ClientPicker from "../client/ClientPicker";
import { api } from "../utils/Api";
import axios from "axios";
import Select from "react-select";

const CreateForecastModal = ({
	createForecast,
	seller,
	sellerName,
	parentCallback,
}) => {
	const [existingCustomer, setExistngCustomer] = useState(false);
	const [period, setPeriod] = useState(new Date());
	const [yardsExpected, setYardsExpected] = useState();
	const [selectedYear, setSelectedYear] = useState();

	const [jan, setJan] = useState(0);
	const [feb, setFeb] = useState(0);
	const [mar, setMar] = useState(0);
	const [apr, setApr] = useState(0);
	const [may, setMay] = useState(0);
	const [jun, setJun] = useState(0);
	const [jul, setJul] = useState(0);
	const [aug, setAug] = useState(0);
	const [sep, setSep] = useState(0);
	const [oct, setOct] = useState(0);
	const [nov, setNov] = useState(0);
	const [dec, setDec] = useState(0);

	const customer = useRef("");
	const customerName = useRef("");

	const yearOptions = [
		// { value: '2022', label: '2022' },
		// { value: '2023', label: '2023' },
		// { value: '2024', label: '2024' },
		{ value: "2025", label: "2025" },
	];

	const selectCustomer = (selected) => {
		customer.current = selected.value;
		customerName.current = selected.label;
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		let monthList = [
			{ month: "01", yards: jan },
			{ month: "02", yards: feb },
			{ month: "03", yards: mar },
			{ month: "04", yards: apr },
			{ month: "05", yards: may },
			{ month: "06", yards: jun },
			{ month: "07", yards: jul },
			{ month: "08", yards: aug },
			{ month: "09", yards: sep },
			{ month: "10", yards: oct },
			{ month: "11", yards: nov },
			{ month: "12", yards: dec },
		];

		if (!customer.current || !selectedYear) {
			return;
		}

		monthList.forEach((month) => {
			const periodDate = `${selectedYear}-${month.month}-01`;
			const yards = month.yards;

			const formData = {
				seller: seller,
				customer: customer.current,
				customerName: customerName.current,
				period: periodDate,
				yardsExpected: yards,
				status: "new",
			};

			createForecastAxios(formData);
		});

		parentCallback("create");
	};

	const createForecastAxios = async (formData) => {
		const url = api + `/forecast`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.post(url, formData);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	return (
		<div>
			<form className="form">
				<div className="form-group row">
					<div className="col-sm-6">
						<label className="text-muted">Seller</label>
						<label className="form-control">{sellerName}</label>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-6">
						<label className="text-muted">Customer</label>
						<ClientPicker action={selectCustomer} />
					</div>
					<div className="col-sm-6">
						<label className="text-muted">Period</label>
						<Select
							options={yearOptions}
							selected={selectedYear}
							onChange={(e) => setSelectedYear(e.value)}
						/>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-6">
						<label className="text-muted">JAN</label>
						<input
							onChange={(e) => setJan(e.target.value)}
							type="number"
							name="JAN"
							className="form-control"
							value={jan}
							placeholder="Yards expected..."
							required
						/>
					</div>
					<div className="col-sm-6">
						<label className="text-muted">FEB</label>
						<input
							onChange={(e) => setFeb(e.target.value)}
							type="number"
							name="FEB"
							className="form-control"
							value={feb}
							placeholder="Yards expected..."
							required
						/>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-6">
						<label className="text-muted">MAR</label>
						<input
							onChange={(e) => setMar(e.target.value)}
							type="number"
							name="MAR"
							className="form-control"
							value={mar}
							placeholder="Yards expected..."
							required
						/>
					</div>
					<div className="col-sm-6">
						<label className="text-muted">APR</label>
						<input
							onChange={(e) => setApr(e.target.value)}
							type="number"
							name="APR"
							className="form-control"
							value={apr}
							placeholder="Yards expected..."
							required
						/>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-6">
						<label className="text-muted">MAY</label>
						<input
							onChange={(e) => setMay(e.target.value)}
							type="number"
							name="MAY"
							className="form-control"
							value={may}
							placeholder="Yards expected..."
							required
						/>
					</div>
					<div className="col-sm-6">
						<label className="text-muted">JUN</label>
						<input
							onChange={(e) => setJun(e.target.value)}
							type="number"
							name="JUN"
							className="form-control"
							value={jun}
							placeholder="Yards expected..."
							required
						/>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-6">
						<label className="text-muted">JUL</label>
						<input
							onChange={(e) => setJul(e.target.value)}
							type="number"
							name="JUL"
							className="form-control"
							value={jul}
							placeholder="Yards expected..."
							required
						/>
					</div>
					<div className="col-sm-6">
						<label className="text-muted">AUG</label>
						<input
							onChange={(e) => setAug(e.target.value)}
							type="number"
							name="AUG"
							className="form-control"
							value={aug}
							placeholder="Yards expected..."
							required
						/>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-6">
						<label className="text-muted">SEP</label>
						<input
							onChange={(e) => setSep(e.target.value)}
							type="number"
							name="SEP"
							className="form-control"
							value={sep}
							placeholder="Yards expected..."
							required
						/>
					</div>
					<div className="col-sm-6">
						<label className="text-muted">OCT</label>
						<input
							onChange={(e) => setOct(e.target.value)}
							type="number"
							name="OCT"
							className="form-control"
							value={oct}
							placeholder="Yards expected..."
							required
						/>
					</div>
				</div>
				<div className="form-group row">
					<div className="col-sm-6">
						<label className="text-muted">NOV</label>
						<input
							onChange={(e) => setNov(e.target.value)}
							type="number"
							name="NOV"
							className="form-control"
							value={nov}
							placeholder="Yards expected..."
							required
						/>
					</div>
					<div className="col-sm-6">
						<label className="text-muted">DEC</label>
						<input
							onChange={(e) => setDec(e.target.value)}
							type="number"
							name="DEC"
							className="form-control"
							value={dec}
							placeholder="Yards expected..."
							required
						/>
					</div>
				</div>
				<br />
				<div className="form-group row">
					<div className="col-6">
						<button className="btn btn-primary" onClick={onSubmit}>
							{" "}
							Save{" "}
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.usuario,
});

export default connect(mapStateToProps, { createForecast })(
	CreateForecastModal
);
