import React, { useEffect, useState } from "react";
import axios from "axios";
import { api } from "../utils/Api";
import { Typography, notification, List, Card, Modal, Tag } from "antd";
import { CustomTable } from "../utils/CustomComponents";

const { Text } = Typography;

const ClaimInfoTop = ({ startDate, endDate, term, changed }) => {
	const [list1, setList1] = useState([]);
	const [list2, setList2] = useState([]);
	const [list3, setList3] = useState([]);
	const [list4, setList4] = useState([]);
	const [loading, setLoading] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);
	const [dataModal, setDataModal] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		setLoading(true);
		Promise.all([
			getLoanTopCustomer(),
			getLoanTopArea(),
			getLoanTopOpen(),
			getLoanTopInvolved(),
		]).then(() => {
			setLoading(false);
		});
	}, [changed]);

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Number",
			dataIndex: "number",
			key: "number",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Claim's Owner",
			dataIndex: "owner",
			key: "onwer",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Area",
			dataIndex: "area",
			key: "area",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Customer's Name",
			dataIndex: "customer",
			key: "customer",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		// {
		// 	title: "Users Involved",
		// 	dataIndex: "usersInvolved",
		// 	key: "usersInvolved",
		// 	render: (value) => {
		// 		return (
		// 			<div>
		// 				{value.map((item) => (
		// 					<p>- {item}</p>
		// 				))}
		// 			</div>
		// 		);
		// 	},
		// },
		{
			title: "Level",
			dataIndex: "level",
			key: "level",
			render: (value) => {
				let color = "default";
				switch (value) {
					case "Low":
						color = "yellow";
						break;

					case "Medium":
						color = "green";
						break;

					case "High":
						color = "red";
						break;

					default:
						break;
				}
				return <Tag color={color}>{value}</Tag>;
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (value) => {
				let color = "default";
				switch (value) {
					case "Open":
						color = "blue";
						break;

					case "In Progress":
						color = "green";
						break;

					case "Closed":
						color = "red";
						break;

					default:
						break;
				}
				return <Tag color={color}>{value}</Tag>;
			},
		},
	];

	const getLoanTopCustomer = async () => {
		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url =
			api +
			`/claim/list/top/customer?startDate=${start}&endDate=${end}&term=${term}`;

		try {
			const { data } = await axios.get(url);
			if (data.success) {
				setList1(data.payload);
			} else {
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const getLoanTopArea = async () => {
		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url =
			api +
			`/claim/list/top/area?startDate=${start}&endDate=${end}&term=${term}`;

		try {
			const { data } = await axios.get(url);

			if (data.success) {
				setList3(data.payload);
			} else {
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const getLoanTopOpen = async () => {
		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url =
			api +
			`/claim/list/top/open?startDate=${start}&endDate=${end}&term=${term}`;

		try {
			const { data } = await axios.get(url);
			if (data.success) {
				setList2(data.payload);
			} else {
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const getLoanTopInvolved = async () => {
		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url =
			api +
			`/claim/list/top/involved?startDate=${start}&endDate=${end}&term=${term}`;

		try {
			const { data } = await axios.get(url);
			if (data.success) {
				setList4(data.payload);
			} else {
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const onClickOpenModal = (type) => {
		let list = [];
		switch (type) {
			case "customer":
				list1.map((item) => {
					item.details.map((item2) => {
						list.push({
							key: item2._id,
							id: item2._id,
							number: item2.number,
							customer: item.customer_details.name,
							owner: item.user_details.name,
							level: item2.level,
							status: item2.status,
							area: item2.area,
							description: item2.description,
							date: Intl.DateTimeFormat("en-US").format(
								new Date(item2.createdAt)
							),
						});
					});
				});
				setDataModal(list);
				break;
			case "open":
				list2.map((item) => {
					list.push({
						key: item._id,
						id: item._id,
						number: item.number,
						customer: item.customer.name,
						owner: item.owner.name,
						level: item.level,
						status: item.status,
						area: item.area,
						description: item.description,
						date: Intl.DateTimeFormat("en-US").format(new Date(item.createdAt)),
					});
				});
				setDataModal(list);
				break;
			case "area":
				setDataModal([]);
				break;
			case "involved":
				setDataModal([]);
				break;
			default:
				break;
		}
		setModalOpen(true);
	};

	const ModalClaim = () => {
		return (
			<Modal
				title="Claim Details"
				open={modalOpen}
				onCancel={() => setModalOpen(false)}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={1000}
			>
				<CustomTable isScroll={false} columns={columns} data={dataModal} />
			</Modal>
		);
	};

	return (
		<>
			<List
				grid={{
					gutter: 16,
					column: 4,
				}}
				dataSource={[
					{
						id: "customer",
						title: "Most Claims by Customer",
						source: list1,
						color: "#b999ff",
					},
					{
						id: "open",
						title: "Open Claims",
						source: list2,
						color: "#99ffff",
					},
					{
						id: "area",
						title: "Claims by Area",
						source: list3,
						color: "#99ff9c",
					},
					{
						id: "involved",
						title: "Involved with most claims",
						source: list4,
						color: "#ffda99",
					},
				]}
				renderItem={(item) => (
					<List.Item>
						<Card
							title={item.title}
							style={{
								backgroundColor: item.color,
								cursor:
									item.id !== "area" && item.id !== "involved"
										? "pointer"
										: "default",
							}}
							loading={loading}
							onClick={
								item.id !== "area" && item.id !== "involved"
									? () => onClickOpenModal(item.id)
									: null
							}
						>
							<ul>
								{item.id === "customer" &&
									item.source.map((sItem, index) => (
										<li key={index}>
											{sItem.customer_details.name} -{" "}
											<Text strong>{sItem.count}</Text>
										</li>
									))}
								{item.id === "area" &&
									item.source.map((sItem, index) => (
										<li key={index}>
											{sItem._id} - <Text strong>{sItem.count}</Text>
										</li>
									))}
								{item.id === "open" &&
									item.source.map((sItem, index) => (
										<li key={index}>
											{sItem.owner.name} -{" "}
											<Text strong>{sItem.customer.name}</Text>({sItem.area})
										</li>
									))}
								{item.id === "involved" &&
									item.source.map((sItem, index) => (
										<li key={index}>
											{sItem.user_details.name} -{" "}
											<Text strong>{sItem.count}</Text>
										</li>
									))}
							</ul>
						</Card>
					</List.Item>
				)}
			/>
			<ModalClaim />
			{contextHolder}
		</>
	);
};

export default ClaimInfoTop;
