import React from "react";
import Alert from "./Alert";
import CustomMenu from "./CustomMenu";
import { Layout as CustomLayout, theme, Typography } from "antd";
// import { SocketProvider } from "../../context/NotificationContext";
const { Content, Footer, Header } = CustomLayout;
const { Title, Text } = Typography;

/**
 ** You can put descriptions based on title, description, className and children components
 */
const Layout = ({
	title = "Title",
	description = "Desc",
	className,
	children,
	type = "large",
}) => {
	const {
		token: { colorBgContainer, borderRadiusLG },
	} = theme.useToken();

	const contentSize = () => {
		switch (type) {
			case "large":
				return {
					padding: "2% 2%",
				};
			case "medium":
				return {
					padding: "2% 10%",
				};

			default:
				return {
					padding: "5% 30%",
				};
		}
	};

	return (
		<CustomLayout style={{ minHeight: "100vh" }}>
			<Header
				style={{
					display: "flex",
					alignItems: "center",
				}}
			>
				{/* <SocketProvider> */}
				<CustomMenu />
				{/* </SocketProvider> */}
				<Alert />
			</Header>
			<Content style={contentSize()}>
				<div
					style={{
						background: colorBgContainer,
						minHeight: 280,
						padding: 24,
						borderRadius: borderRadiusLG,
					}}
				>
					{/* <Image
						width={250}
						src="/images/concrete57.png"
						// src="http://57concrete.com/assets/images/logo/logo-light.png"
						preview={false}
					/> */}
					<Title style={{ margin: "0 0 5px" }}>{title}</Title>
					<Text style={{ fontSize: 20 }} type="secondary">
						{description}
					</Text>
					{children}
				</div>
			</Content>
			<Footer
				style={{
					textAlign: "center",
				}}
			>
				57Concrete ©{new Date().getFullYear()}
			</Footer>
		</CustomLayout>
	);
};

export default Layout;
