import {
	Button,
	Col,
	DatePicker,
	Divider,
	Form,
	Input,
	InputNumber,
	Row,
	notification,
} from "antd";
import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { api } from "../utils/Api";
import axios from "axios";
import dayjs from "dayjs";
import { CustomSpinner } from "../utils/CustomComponents";

const Lien = ({ history, match }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [loadingButton, setLoadingButton] = useState(false);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [initialValues, setInitialValues] = useState({});

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getLien();
	}, []);

	const getLien = async () => {
		setLoading(true);
		try {
			const url = api + "/lien/get/" + match.params.id;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			const { data } = await axios.get(url);
			if (data.success) {
				const lien = data.payload;
				setInitialValues({
					ownerName: lien.ownerName,
					ownerAddress: lien.ownerAddress,
					contractorName: lien.contractorName,
					contractorAddress: lien.contractorAddress,
					companyName: lien.companyName,
					companyAddress: lien.companyAddress,
					propertyAddress: lien.propertyAddress,
					deadline: dayjs(lien.deadline),
					descriptionMaterials: lien.descriptionMaterials,
					dateDelivered: dayjs(lien.dateDelivered),
					amountCharged: lien.amountCharged,
					principalAmountOwned: lien.principalAmountOwned,
					montlyInterestAmount: lien.montlyInterestAmount,
					totalAmountOwned: lien.totalAmountOwned,
					signorName: lien.signorName,
				});
			} else {
				openNotification("error", "Error", data.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			openNotification("error", "Error", "Error while trying to get lien");
			console.error(err.message);
			return null;
		}
	};

	const updateLien = async (data) => {
		const lien = {
			...data,
		};

		try {
			let url = api + "/lien/update/" + match.params.id;

			const { data } = await axios.patch(url, lien);
			if (data.success) {
				openNotification("success", "Success", data.message);

				setTimeout(() => {
					setLoadingButton(false);
					history.push("/lien/dashboard");
				}, 2000);
			} else {
				setLoadingButton(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			setLoadingButton(false);
			openNotification("error", "Error", "Error while trying to update lien");
			console.error(err.message);
			return null;
		}
	};

	const onFinish = () => {
		setLoadingButton(true);
		const values = form.getFieldsValue();
		let finalValues = {
			...values,
			deadline: dayjs(values.deadline).toDate(),
			dateDelivered: dayjs(values.dateDelivered).toDate(),
		};
		updateLien(finalValues);
	};

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				style={{ marginTop: "20px" }}
				initialValues={initialValues}
			>
				<Divider />
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Property Owner's Name"
							name="ownerName"
							rules={[
								{
									required: true,
									message: "Property Owner's Name is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Property Owner's Address"
							name="ownerAddress"
							rules={[
								{
									required: true,
									message: "Property Owner's Address is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Contractor's Name"
							name="contractorName"
							rules={[
								{
									required: true,
									message: "Contractor's Name is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Contractor's Address"
							name="contractorAddress"
							rules={[
								{
									required: true,
									message: "Contractor's Address is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Company Name"
							name="companyName"
							rules={[
								{
									required: true,
									message: "Company Name is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Company Address"
							name="companyAddress"
							rules={[
								{
									required: true,
									message: "Company Address is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Property Address"
							name="propertyAddress"
							rules={[
								{
									required: true,
									message: "Property Address is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Deadline"
							name="deadline"
							rules={[
								{
									required: true,
									message: "Deadline Address is required",
								},
							]}
						>
							<DatePicker
								size="large"
								style={{ width: "100%" }}
								format={"MM/DD/YYYY"}
								allowClear={false}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={24} sm={12} lg={8}>
						<Form.Item
							label="Description of Materials	"
							name="descriptionMaterials"
							rules={[
								{
									required: true,
									message: "Description of Materials is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={8}>
						<Form.Item
							label="Date Delivered"
							name="dateDelivered"
							rules={[
								{
									required: true,
									message: "Date Delivered is required",
								},
							]}
						>
							<DatePicker
								size="large"
								style={{ width: "100%" }}
								format={"MM/DD/YYYY"}
								allowClear={false}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={8}>
						<Form.Item
							label="Price of Materials"
							name="amountCharged"
							rules={[
								{
									required: true,
									message: "Price of Materials is required",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								step={"0.001"}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={24} sm={12} lg={8}>
						<Form.Item
							label="Princial Amount Owned"
							name="principalAmountOwned"
							rules={[
								{
									required: true,
									message: "Princial Amount Owned is required",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								step={"0.001"}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={8}>
						<Form.Item
							label="Montly Interest Amount"
							name="montlyInterestAmount"
							rules={[
								{
									required: true,
									message: "Montly Interest Amount is required",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								step={"0.001"}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={8}>
						<Form.Item
							label="Total Amount Owned"
							name="totalAmountOwned"
							rules={[
								{
									required: true,
									message: "Total Amount Owned is required",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								step={"0.001"}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							label="Signor's Name"
							name="signorName"
							rules={[
								{
									required: true,
									message: "Signor's Name is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12} md={4}>
						<Button
							type="primary"
							htmlType="submit"
							loading={loadingButton}
							size="large"
							style={{
								width: "100%",
							}}
						>
							Save
						</Button>
					</Col>
				</Row>{" "}
			</Form>
		);
	};

	return (
		<Layout
			title="Update Lien"
			description="Edit current lien values"
			type="medium"
		>
			{contextHolder}
			{loading ? <CustomSpinner /> : <CustomForm />}
		</Layout>
	);
};

export default Lien;
