import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	notification,
	Statistic,
	DatePicker,
	Select,
	Button,
} from "antd";
import Layout from "../layout/Layout";
import { CustomSpinner, CustomTable } from "../utils/CustomComponents";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";

const { Text } = Typography;

const columnsCSV = [
	{
		label: "Week",
		key: "week",
	},
	{
		label: "Yards",
		key: "yards",
	},
];

const JobYardsByWeekReport = () => {
	const [loading, setLoading] = useState(false);
	const [weekList, setWeekList] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [date, setDate] = useState(dayjs());
	const [plants, setPlants] = useState([]);
	const [plant, setPlant] = useState("All");
	const refDownload = useRef();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Week",
			dataIndex: "week",
			key: "week",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Yards",
			dataIndex: "yards",
			key: "yards",
			render: (value) => {
				return (
					<Statistic title="" value={value} valueStyle={{ fontSize: 14 }} />
				);
			},
		},
	];

	useEffect(() => {
		getPlants();
	}, []);

	const getJobYards = async (year, plant) => {
		setLoading(true);

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url = api + `/job/list/yardsbyweek?year=${year}&plant=${plant}`;

		try {
			const { data } = await axios.get(url);
			let list = [];

			if (data.success) {
				data.payload.map((item, index) => {
					list.push({
						key: index,
						id: item._id.week,
						week: item._id.week,
						yards: item.totalYards,
					});
				});
				setWeekList(list);
			} else {
				openNotification("error", "Error", data.message);
			}

			setLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<CustomTable columns={columns} data={weekList} isScroll={true} />
			</Flex>
		);
	};

	const getPlants = async () => {
		setLoading(true);
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url = api + `/plant/list/options`;

		try {
			const { data } = await axios.get(url);
			let list = [
				{
					value: "All",
					label: "All",
				},
			];

			if (data.success) {
				data.payload.map((item) => {
					list.push({
						value: item._id,
						label: item.name,
					});
				});
				setPlants(list);
				const year = date.year();
				getJobYards(year, "All");
			} else {
				openNotification("error", "Error", data.message);
				setLoading(false);
			}
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const onDateChange = (date, dateString) => {
		setDate(date);
		getJobYards(dateString, plant);
	};

	const disabledYears = (current) => {
		return current.year() < 2023;
	};

	const onChangePlant = (value) => {
		if (value) {
			const year = date.year();
			getJobYards(year, value);
			setPlant(value);
		}
	};

	const RenderSearch = () => {
		return (
			<Flex gap={"middle"}>
				<DatePicker
					onChange={onDateChange}
					picker="year"
					size="large"
					disabledDate={disabledYears}
					value={date}
					allowClear={false}
					style={{ width: 200 }}
					disabled={loading}
				/>
				<Select
					showSearch
					placeholder={`Select plant`}
					optionFilterProp="children"
					size="large"
					onChange={onChangePlant}
					options={plants}
					disabled={loading}
					value={plant}
					style={{
						width: "200px",
					}}
				/>
				<Button
					type="primary"
					style={{ width: "200px" }}
					onClick={(e) => refDownload.current.link.click()}
					loading={loading}
					size="large"
				>
					Download Report
				</Button>
				<CSVLink
					data={weekList}
					headers={columnsCSV}
					filename={"57concrete-job-yards-by-week.csv"}
					hidden
					ref={refDownload}
					style={{ width: 200 }}
				>
					Download report
				</CSVLink>
			</Flex>
		);
	};

	return (
		<Layout title="Job Yards By Week Report" description="Report">
			{contextHolder}
			<Flex vertical gap={"large"}>
				<RenderSearch />
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default JobYardsByWeekReport;
