import { Flex, notification } from "antd";
import React, { useEffect, useState } from "react";
import { api } from "../utils/Api";
import axios from "axios";
import GoogleMapReact from "google-map-react";
import { CustomSpinner } from "../utils/CustomComponents";
import Marker from "../map/Marker";
import { Typography } from "antd";
const { Title } = Typography;

const OpportunityQuadrantType = ({ type, map }) => {
	const [loading, setLoading] = useState(true);
	const [coords, setCoords] = useState([]);
	const [heatMapPositions, setHeatMapPositions] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	const googleApi =
		process.env.REACT_APP_GOOGLE_MAP_API ||
		"AIzaSyClyO950zwD8xsLYO7VHcyrnokEU6njbVs";

	useEffect(() => {
		getOpportunityList();
	}, [type]);

	const getOpportunityList = async () => {
		setLoading(true);

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		let quadrant = "Mission";
		switch (type) {
			case "mission":
				quadrant = "Mission";
				break;
			case "sanbenito":
				quadrant = "San Benito";

				break;
			case "other":
				quadrant = "Other";
				break;
			default:
				break;
		}
		const url = api + `/opportunity/list/pins/${quadrant}`;

		try {
			const { data } = await axios.get(url);
			let list = [];
			if (data.success) {
				if (map === "pinmap") {
					data.payload.map((item) => {
						list.push({
							latitude: item.latitude,
							longitude: item.longitude,
							status: item.status,
						});
					});
					setCoords(list);
				} else if (map === "heatmap") {
					data.payload.map((item) => {
						list.push({
							lat: item.latitude,
							lng: item.longitude,
						});
					});
					setHeatMapPositions(list);
				}
			} else {
				openNotification("error", "Error", data.message);
			}

			setLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const defaultMap = {
		center: {
			lat: 26.2787785,
			lng: -98.4021818,
		},
		zoom: 8,
	};

	const fitBounds = (map, maps) => {
		var bounds = new maps.LatLngBounds();
		console.log("corrds", coords);
		for (let marker of coords) {
			bounds.extend(new maps.LatLng(marker.latitude, marker.longitude));
		}
		map.fitBounds(bounds);
	};

	const fitBounds2 = (map, maps) => {
		var bounds = new maps.LatLngBounds();
		for (let marker of heatMapPositions) {
			bounds.extend(new maps.LatLng(marker.lat, marker.lng));
		}
		map.fitBounds(bounds);
	};

	const onChangeColorStatus = (status) => {
		let color = "gray";
		switch (status) {
			case "New":
				color = "blue";
				break;
			case "Localize":
				color = "gold";
				break;
			case "Contact":
				color = "cyan";
				break;
			case "Negotiation":
				color = "magenta";
				break;
			case "Closed":
				color = "green";
				break;
			case "Lost":
				color = "red";
				break;

			default:
				break;
		}
		return color;
	};

	const PinMap = () => (
		<GoogleMapReact
			style={{
				width: "100%",
				height: "60vh",
			}}
			bootstrapURLKeys={{ key: googleApi }}
			defaultCenter={defaultMap.center}
			defaultZoom={defaultMap.zoom}
			yesIWantToUseGoogleMapApiInternals
			onGoogleApiLoaded={({ map, maps }) => fitBounds(map, maps)}
		>
			{coords.map((coord) => (
				<Marker
					lat={coord.latitude}
					lng={coord.longitude}
					color={onChangeColorStatus(coord.status)}
				/>
			))}
		</GoogleMapReact>
	);

	const HeatMap = () => (
		<GoogleMapReact
			style={{
				width: "100%",
				height: "60vh",
			}}
			bootstrapURLKeys={{ key: googleApi }}
			defaultCenter={defaultMap.center}
			defaultZoom={defaultMap.zoom}
			yesIWantToUseGoogleMapApiInternals
			heatmapLibrary={true}
			heatmap={{
				positions: heatMapPositions,
				options: { radius: 20, opacity: 0.6 },
			}}
			onGoogleApiLoaded={({ map, maps }) => fitBounds2(map, maps)}
		/>
	);

	return (
		<div>
			<Flex align="center" justify="center">
				{loading ? (
					<CustomSpinner />
				) : map === "pinmap" ? (
					coords.length === 0 ? (
						<Title level={4} style={{ margin: "30px 0px" }}>
							No pins assigned
						</Title>
					) : (
						<PinMap />
					)
				) : heatMapPositions.length === 0 ? (
					<Title level={4} style={{ margin: "30px 0px" }}>
						No heatmap assigned
					</Title>
				) : (
					<HeatMap />
				)}
			</Flex>

			{contextHolder}
		</div>
	);
};

export default OpportunityQuadrantType;
