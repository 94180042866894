import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	Button,
	notification,
	Row,
	Col,
	Table,
	Checkbox,
} from "antd";
import Layout from "../layout/Layout";
import { CustomSpinner } from "../utils/CustomComponents";
import { EditFilled } from "@ant-design/icons";
import { CSVLink } from "react-csv";
const { Text } = Typography;

const columnsCSV = [
	{ key: "number", label: "Vehicle No." },
	{ key: "type", label: "Vehicle Type" },
	{ key: "year", label: "Year" },
	{ key: "make", label: "Make" },
	{ key: "model", label: "Model" },
	{ key: "gvwr", label: "GVWR" },
	{ key: "vin", label: "Vehicle ID Number (VIN)" },
	{ key: "plates", label: "License Plates" },
	{ key: "state", label: "License Plates State" },
	{ key: "property", label: "Leased or Owned" },
	{ key: "sticker", label: "Sticker / Plates" },
	{ key: "texasReadyMixPermit", label: "Texas Ready Mix Permit Exp" },
	{ key: "permitNumber1", label: "Permit Number" },
	{ key: "overAxiePermit", label: "Over Axie Permit" },
	{ key: "permitNumber2", label: "Permit Number" },
	{ key: "replacementDieselTags", label: "Replacement Diesel Tags" },
	{ key: "comments", label: "Comments" },
	{ key: "registration", label: "Registration" },
	{ key: "insuranceExpiration", label: "Insurance" },
	{ key: "readyMixPermit", label: "Ready Mix Permit" },
	{ key: "inspection", label: "Inspection" },
	{ key: "overweight", label: "Overweight" },
	{ key: "insuranceCabCard", label: "Insurance Cab Card" },
	{ key: "drugTestValue", label: "Drug Test" },
	{ key: "annualInspection", label: "Annual Inspection" },
	{ key: "cleaning", label: "Cleaning Check List" },
	{ key: "observations", label: "Observations" },
	{ key: "bookValue", label: "Book" },
	// { key: "insurance", label: "Insurance" },
	// { key: "insuranceExpiration", label: "	Insurance Expiration" },
];

const OfficeTruckList = ({ history }) => {
	const [loading, setLoading] = useState(false);
	const [vehicleList, setVehicleList] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();
	const refDownload = useRef();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "",
			dataIndex: "edit",
			width: 100,
			render: (_, record) => {
				return (
					<Row gutter={16}>
						<Button
							type="primary"
							icon={<EditFilled twoToneColor={"white"} />}
							onClick={(e) => history.push(`/office/truck/update/${record.id}`)}
						/>
					</Row>
				);
			},
		},
		{
			title: "Truck No.",
			dataIndex: "number",
			key: "number",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Vehicle Type",
			dataIndex: "type",
			key: "type",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Year",
			dataIndex: "year",
			key: "year",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Make",
			dataIndex: "make",
			key: "make",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Model",
			dataIndex: "model",
			key: "model",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "GVWR",
			dataIndex: "gvwr",
			key: "gvwr",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Vehicle ID Number (VIN)",
			dataIndex: "vin",
			key: "vin",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "License Plates",
			dataIndex: "plates",
			key: "plates",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "License Plates State",
			dataIndex: "state",
			key: "state",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Leased or Owned",
			dataIndex: "property",
			key: "property",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Sticker / Plates",
			dataIndex: "sticker",
			key: "sticker",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Texas Ready Mix Permit Exp",
			dataIndex: "texasReadyMixPermit",
			key: "texasReadyMixPermit",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Permit Number",
			dataIndex: "permitNumber1",
			key: "permitNumber1",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Over Axie Permit",
			dataIndex: "overAxiePermit",
			key: "overAxiePermit",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Permit Number",
			dataIndex: "permitNumber2",
			key: "permitNumber2",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Replacement Diesel Tags",
			dataIndex: "replacementDieselTags",
			key: "replacementDieselTags",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Comments",
			dataIndex: "comments",
			key: "comments",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Active",
			dataIndex: "active",
			key: "active",
			render: (value) => {
				return <Checkbox checked={value} />;
			},
		},
	];

	const expandedRowRender = (row) => {
		const columns = [
			{
				title: "Registration",
				dataIndex: "registration",
				key: "registration",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Insurance",
				dataIndex: "insuranceExpiration",
				key: "insuranceExpiration",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Ready Mix Permit",
				dataIndex: "readyMixPermit",
				key: "readyMixPermit",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Inspection",
				dataIndex: "inspection",
				key: "inspection",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Overweight",
				dataIndex: "overweight",
				key: "overweight",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Insurance Cab Card",
				dataIndex: "insuranceCabCard",
				key: "insuranceCabCard",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Drug Test",
				dataIndex: "drugTest",
				key: "drugTest",
				render: (value) => {
					return <Checkbox checked={value} />;
				},
			},
			{
				title: "Annual Inspection",
				dataIndex: "annualInspection",
				key: "annualInspection",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Cleaning Check List",
				dataIndex: "cleaning",
				key: "cleaning",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Observations",
				dataIndex: "observations",
				key: "observations",
				render: (value) => {
					return <Text>{value}</Text>;
				},
			},
			{
				title: "Book",
				dataIndex: "book",
				key: "book",
				render: (value) => {
					return <Checkbox checked={value} />;
				},
			},
		];

		let items = [
			{
				key: row.key,
				registration: row.registration,
				insuranceExpiration: row.insuranceExpiration,
				readyMixPermit: row.readyMixPermit,
				inspection: row.inspection,
				overweight: row.overweight,
				insuranceCabCard: row.insuranceCabCard,
				drugTest: row.drugTest,
				annualInspection: row.annualInspection,
				cleaning: row.cleaning,
				observations: row.observations,
				book: row.book,
			},
		];

		return <Table columns={columns} dataSource={items} pagination={false} />;
	};

	useEffect(() => {
		getVehicleList();
	}, []);

	const getVehicleList = async () => {
		setLoading(true);

		const url = api + `/office/truck/list`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			if (data.success) {
				data.payload.map((item, index) => {
					list.push({
						key: index,
						id: item._id,
						type: item.type || "",
						year: item.year || "",
						make: item.make || "",
						model: item.model || "",
						vin: item.vin || "",
						plates: item.plates || "",
						state: item.state || "",
						property: item.property || "",
						sticker: item.sticker
							? Intl.DateTimeFormat("en-US", {
									month: "short",
									year: "numeric",
							  }).format(new Date(item.sticker))
							: "",
						permitNumber1: item.permitNumber1 || "",
						permitNumber2: item.permitNumber2 || "",
						replacementDieselTags: item.replacementDieselTags || "",
						texasReadyMixPermit: item.texasReadyMixPermit
							? Intl.DateTimeFormat("en-US").format(
									new Date(item.texasReadyMixPermit)
							  )
							: "",
						overAxiePermit: item.overAxiePermitPending
							? "Payment Pending"
							: item.overAxiePermit
							? Intl.DateTimeFormat("en-US").format(
									new Date(item.overAxiePermit)
							  )
							: "",
						comments: item.comments || "",
						number: item.number || "",
						gvwr: item.gvwr || "",
						registration: item.registration
							? Intl.DateTimeFormat("en-US").format(new Date(item.registration))
							: "",
						insuranceExpiration: item.insuranceExpiration
							? Intl.DateTimeFormat("en-US").format(
									new Date(item.insuranceExpiration)
							  )
							: "",
						readyMixPermit: item.readyMixPermit
							? Intl.DateTimeFormat("en-US").format(
									new Date(item.readyMixPermit)
							  )
							: "",
						inspection: item.inspection
							? Intl.DateTimeFormat("en-US").format(new Date(item.inspection))
							: "",
						overweight: item.overweight
							? Intl.DateTimeFormat("en-US").format(new Date(item.overweight))
							: "",
						insuranceCabCard: item.insuranceCabCard
							? Intl.DateTimeFormat("en-US").format(
									new Date(item.insuranceCabCard)
							  )
							: "",
						drugTest: item.drugTest,
						annualInspection: item.annualInspection
							? Intl.DateTimeFormat("en-US").format(
									new Date(item.annualInspection)
							  )
							: "",
						cleaning: item.cleaning || "",
						observations: item.observations || "",
						book: item.book,
						bookValue: item.book ? "Yes" : "No",
						drugTestValue: item.drugTest ? "Yes" : "No",
						active:
							item.active === undefined || item.active === true ? true : false,
					});
				});
				setVehicleList(list);
			} else {
				openNotification("error", "Error", data.message);
			}

			setLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const onClickNewVehicle = () => {
		history.push("/office/truck/new");
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Row gutter={[16, 8]}>
					<Col span={24} xs={12} md={8} xl={4} xxl={3}>
						<Button
							type="primary"
							style={{ width: "100%" }}
							onClick={onClickNewVehicle}
							size="large"
							loading={loading}
						>
							New truck
						</Button>
					</Col>
					<Col span={24} xs={12} md={8} xl={4} xxl={3}>
						<Button
							type="primary"
							style={{ width: "100%" }}
							onClick={(e) => refDownload.current.link.click()}
							loading={loading}
							size="large"
						>
							Download Report
						</Button>
						<CSVLink
							data={vehicleList.filter((item) => item.active)}
							headers={columnsCSV}
							filename={"57concrete-office-trucks.csv"}
							hidden
							ref={refDownload}
						>
							Download report
						</CSVLink>
					</Col>
				</Row>
				&nbsp;
				<Table
					columns={columns}
					dataSource={vehicleList}
					expandable={{
						expandedRowRender,
					}}
					pagination={{
						position: ["bottomRight"],
						pageSizeOptions: [10, 20, 50, 100],
						defaultPageSize: 100,
						showSizeChanger: true,
					}}
					scroll={{
						x: true,
					}}
					style={{
						minWidth: "100%",
					}}
				/>
			</Flex>
		);
	};

	return (
		<Layout title="Office Trucks" description="Truck list">
			{contextHolder}
			<Flex vertical gap={"large"}>
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default OfficeTruckList;
