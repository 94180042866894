import {
	Button,
	Checkbox,
	Col,
	Divider,
	Form,
	Input,
	Row,
	Select,
	Upload,
	notification,
	Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { api } from "../utils/Api";
import axios from "axios";
import dayjs from "dayjs";
import { CustomSpinner } from "../utils/CustomComponents";
import { UploadOutlined } from "@ant-design/icons";
import ClaimJobModal from "./ClaimJobModal";
const { Text } = Typography;

const optionsClaimArea = [
	{
		value: "QC",
		label: "QC",
	},
	{
		value: "Logistics",
		label: "Logistics",
	},
	{
		value: "Production",
		label: "Production",
	},
	{
		value: "Operations",
		label: "Operations",
	},
	{
		value: "Sales",
		label: "Sales",
	},
	{
		value: "A/R",
		label: "A/R",
	},
];

const optionsLevel = [
	{
		value: "Low",
		label: "Low (3 Days)",
	},
	{
		value: "Medium",
		label: "Medium (1-2 Days)",
	},
	{
		value: "High",
		label: "High (24 Hrs)",
	},
];

const NewClaim = ({ history }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(true);
	const [loadingButton, setLoadingButton] = useState(false);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [evidenceAdded, setEvidenceAdded] = useState(false);
	const [customerList, setCustomerList] = useState([]);
	const [customerLoading, setCustomerLoading] = useState(true);
	const [userList, setUserList] = useState([]);
	const [userLoading, setUserLoading] = useState(true);
	const [jobList, setJobList] = useState([]);
	const [jobLoading, setJobLoading] = useState(false);
	const [loadList, setLoadList] = useState([]);
	const [loadLoading, setLoadLoading] = useState(true);
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());
	const [openSearchJobModal, setOpenSearchJobModal] = useState(false);
	const [jobSelected, setJobSelected] = useState({});
	const [claimNumber, setClaimNumber] = useState(null);

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getCustomers();
		getUsers();
		getClaimID();
	}, []);

	const getClaimID = async () => {
		try {
			const url = api + "/claimnumber";
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.get(url);
			setClaimNumber(data.claimNumber);
			setLoading(false);
		} catch (err) {
			setCustomerLoading(false);
			openNotification("error", "Error", "Error while trying to get claim id");
			console.error(err.message);
			return null;
		}
	};

	const getCustomers = async () => {
		try {
			const url = api + "/customer/list/options";
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.get(url);
			if (data.success) {
				let list = [];
				data.payload.map((item) => {
					list.push({
						label: `${item.customerNumber} ${item.name}`,
						value: item._id,
					});
				});
				setCustomerList(list);
			} else {
				openNotification("error", "Error", data.message);
			}
			setCustomerLoading(false);
		} catch (err) {
			setCustomerLoading(false);
			openNotification("error", "Error", "Error while trying to get customers");
			console.error(err.message);
			return null;
		}
	};

	const getUsers = async () => {
		try {
			const url = api + "/user/list/options";
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.get(url);
			if (data.success) {
				let list = [];
				data.payload.map((item) => {
					list.push({
						label: `${item.role} - ${item.name.toUpperCase()}`,
						value: item._id,
					});
				});
				setUserList(list);
			} else {
				openNotification("error", "Error", data.message);
			}
			setUserLoading(false);
		} catch (err) {
			setUserLoading(false);
			openNotification("error", "Error", "Error while trying to get users");
			console.error(err.message);
			return null;
		}
	};

	const getJobs = async (term) => {
		try {
			setJobLoading(true);
			const start = `${startDate.year()}-${
				startDate.month() + 1
			}-${startDate.date()}`;
			const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

			const url =
				api +
				`/job/list/options?startDate=${start}&endDate=${end}&term=${term}`;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.get(url);
			if (data.success) {
				let list = [];
				data.payload.map((item) => {
					list.push({
						key: item._id,
						id: item._id,
						number: item.number,
						customer: item.customer.name,
						seller: item.seller.name,
						plant: item.plant.name,
						address: item.shipAddress,
					});
				});
				setJobList(list);
			} else {
				openNotification("error", "Error", data.message);
			}
			setJobLoading(false);
		} catch (err) {
			setJobLoading(false);
			openNotification("error", "Error", "Error while trying to get jobs");
			console.error(err.message);
			return null;
		}
	};

	const getLoads = async (id) => {
		setLoadLoading(true);
		try {
			const url = api + `/load/list/byjob/${id}`;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.get(url);
			if (data.success) {
				let list = [];
				data.payload.map((item) => {
					list.push({
						label: `${item.deliveryTicket}`,
						value: item._id,
					});
				});
				setLoadList(list);
			} else {
				openNotification("error", "Error", data.message);
			}
			setLoadLoading(false);
		} catch (err) {
			setLoadLoading(false);
			openNotification("error", "Error", "Error while trying to get loads");
			console.error(err.message);
			return null;
		}
	};

	const createClaim = async (values) => {
		const claim = {
			...values,
			job: jobSelected.id ? jobSelected.id : undefined,
			number: claimNumber,
		};

		delete claim.evidence;

		try {
			const url = api + "/claim/create";
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.post(url, claim);
			if (data.success) {
				if (evidenceAdded) {
					addEvidence(values, data.payload._id);
				} else {
					openNotification("success", "Success", data.message);

					setTimeout(() => {
						setLoadingButton(false);
						history.push("/claim/dashboard");
					}, 2000);
				}
			} else {
				setLoadingButton(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			setLoadingButton(false);
			openNotification("error", "Error", "Error while trying to create claim");
			console.error(err.message);
			return null;
		}
	};

	const addEvidence = async (values, id) => {
		const formData = new FormData();

		if (evidenceAdded && values.evidence && values.evidence.length > 0) {
			values.evidence.forEach((file) => {
				formData.append("files", file.originFileObj);
			});
		}

		try {
			const url = api + `/claim/add/files/${id}`;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
			const config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};

			const { data } = await axios.post(url, formData, config);
			if (data.success) {
				openNotification("success", "Success", "Claim Created");

				setTimeout(() => {
					setLoadingButton(false);
					history.push("/claim/dashboard");
				}, 2000);
			} else {
				setLoadingButton(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			setLoadingButton(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to add images claim"
			);
			console.error(err.message);
			return null;
		}
	};

	const normFile = (e) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e?.fileList;
	};

	const onChangeEvidence = ({ fileList }) => {
		if (fileList.length === 0) {
			setEvidenceAdded(false);
		} else {
			setEvidenceAdded(true);
		}
	};

	const onFinish = (values) => {
		setLoadingButton(true);
		createClaim(values);
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const onSearch = (value, _e, info) => {
		if (info?.source === "input") {
			getJobs(value);
		}
	};

	const columnsJob = [
		{
			title: "Number",
			dataIndex: "number",
			key: "number",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Customer",
			dataIndex: "customer",
			key: "customer",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Shipping Address",
			dataIndex: "address",
			key: "address",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Seller",
			dataIndex: "seller",
			key: "seller",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Plant",
			dataIndex: "plant",
			key: "plant",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
	];

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	};

	const handleSearchJobCancel = useCallback(() => {
		setOpenSearchJobModal(false);
	}, []);

	const handleSearchJobOpen = useCallback(() => {
		setOpenSearchJobModal(true);
	}, []);

	const onAccept = useCallback(() => {
		handleSearchJobCancel();
		if (jobSelected.number) {
			form.setFieldValue("job", jobSelected.number);
			getLoads(jobSelected.id);
		}
	}, [jobSelected, handleSearchJobCancel, form]);

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				style={{ marginTop: "20px" }}
				initialValues={{
					internal: false,
					external: false,
				}}
			>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Claim Area"
							name="area"
							rules={[
								{
									required: true,
									message: "Claim Area is required",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select area"
								options={optionsClaimArea}
								showSearch
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Customer"
							name="customer"
							rules={[
								{
									required: true,
									message: "Customer is required",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select customer"
								options={customerList}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								loading={customerLoading}
							/>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							label="Description"
							name="description"
							rules={[
								{
									required: true,
									message: "Description is required",
								},
							]}
						>
							<Input.TextArea size="large" />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item label="Users Involved" name="usersInvolved">
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select users"
								mode="multiple"
								options={userList}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								allowClear
								loading={userLoading}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Level"
							name="level"
							rules={[
								{
									required: true,
									message: "Level is required",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select level"
								options={optionsLevel}
								showSearch
							/>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col span={24} xs={12}>
						<Form.Item
							label="Evidence (Max Count: 10)"
							name="evidence"
							valuePropName="fileList"
							getValueFromEvent={normFile}
						>
							<Upload
								beforeUpload={() => false}
								maxCount={10}
								multiple={true}
								accept=".pdf, .csv, .xls, .png, .jpg, .jpeg"
								onChange={onChangeEvidence}
							>
								<Button icon={<UploadOutlined />} size="large">
									Upload
								</Button>
							</Upload>
						</Form.Item>
					</Col>
				</Row>
				<Divider />
				<p>
					<Text strong>Job/Load Details</Text>
				</p>
				<Button
					size="large"
					type="primary"
					htmlType="button"
					onClick={handleSearchJobOpen}
				>
					Search Job
				</Button>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item name="job" label="Job">
							<Input disabled size="large" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item name="load" label="Load">
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select a load"
								options={loadList}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								loading={loadLoading}
								disabled={loadLoading}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Divider />
				<Text strong>Send Messages</Text>

				<Row gutter={16}>
					<Col span={12}>
						<Form.Item name="internal" valuePropName="checked">
							<Checkbox>Internal</Checkbox>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item name="external" valuePropName="checked">
							<Checkbox>External</Checkbox>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col span={24} sm={12} md={4}>
						<Button
							type="primary"
							htmlType="submit"
							loading={loadingButton}
							size="large"
							style={{
								width: "100%",
							}}
						>
							Create
						</Button>
					</Col>
				</Row>
			</Form>
		);
	};

	return (
		<Layout title="New Claim" description="Create new claim" type="medium">
			{contextHolder}
			{loading ? <CustomSpinner /> : <CustomForm />}
			<ClaimJobModal
				open={openSearchJobModal}
				onCancel={handleSearchJobCancel}
				jobList={jobList}
				loading={jobLoading}
				columns={columnsJob}
				onAccept={onAccept}
				onSearch={onSearch}
				onRangeChange={onRangeChange}
				jobLoading={jobLoading}
				setJobSelected={setJobSelected}
				startDate={startDate}
				endDate={endDate}
			/>
		</Layout>
	);
};

export default NewClaim;
