import {
	Button,
	Col,
	Divider,
	Form,
	Input,
	Modal,
	Row,
	Select,
	notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { api } from "../utils/Api";
import axios from "axios";
import AddressGeolocation2 from "../map/AddressGeolocation2";
import { CustomSpinner } from "../utils/CustomComponents";
const { Option } = Select;

const isAdminUser =
	localStorage.getItem("email") === "eliud@gmail.com" ||
	localStorage.getItem("email") === "ec@57concrete.com" ||
	localStorage.getItem("email") === "bp@57concrete.com" ||
	localStorage.getItem("email") === "mgr@57concrete.com" ||
	localStorage.getItem("email") === "jh@57concrete.com" ||
	localStorage.getItem("email") === "rl@57concrete.com";

const opportuniyTypeOptions = [
	{
		value: "Residential",
		label: "Residential",
	},
	{
		value: "Commercial",
		label: "Commercial",
	},
];

const OpportunityNew = ({ id, callback, type }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(true);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [openSearchAddressModal, setOpenSearchAddressModal] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [optionsSellers, setOptionsSellers] = useState([]);
	const [loadingSeller, setLoadingSeller] = useState(true);

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		if (isAdminUser) {
			getSellers();
		}
		getOpportunity();
	}, []);

	const getSellers = async () => {
		const url = api + "/userlist/Seller";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item) => {
				list.push({
					value: item._id,
					label: item.name,
				});
			});
			setOptionsSellers(list);
			setLoadingSeller(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getOpportunity = async () => {
		try {
			const url = api + "/opportunity/get/" + id;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			const { data } = await axios.get(url);
			if (data.success) {
				const opportunity = data.payload;
				setInitialValues({
					prefix: "+1",
					latitude: opportunity.latitude || undefined,
					longitude: opportunity.longitude || undefined,
					address: opportunity.address || undefined,
					contactEmail: opportunity.contactEmail || undefined,
					contactPhone: opportunity.contactPhone
						? opportunity.contactPhone.substring(2)
						: undefined,
					contactName: opportunity.contactName || undefined,
					locatorPerson: opportunity.locatorPerson || undefined,
					seller: opportunity.seller || undefined,
					opportunityType: opportunity.opportunityType || undefined,
				});
			} else {
				openNotification("error", "Error", data.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to get opportunity"
			);
			console.error(err.message);
			return null;
		}
	};

	const updateOpportunity = async (data) => {
		const opportunity = {
			...data,
		};

		if (data.contactPhone) opportunity.contactPhone = "+1" + data.contactPhone;
		delete opportunity.prefix;

		if (type === "localize") opportunity.status = "Contact";

		try {
			const url = api + "/opportunity/update/" + id;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			const { data } = await axios.patch(url, opportunity);
			if (data.success) {
				const payload = data.payload;

				openNotification("success", "Success", data.message);

				setTimeout(() => {
					callback(payload.status);
					setLoading(false);
				}, 2000);
			} else {
				openNotification("error", "Error", data.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to update opportunity"
			);
			console.error(err.message);
			return null;
		}
	};

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select
				style={{
					width: 70,
				}}
			>
				<Option value="+1">+1</Option>
			</Select>
		</Form.Item>
	);

	const onFinish = () => {
		setLoading(true);
		const values = form.getFieldsValue(true);
		if (
			!values.address &&
			!values.contactEmail &&
			!values.contactName &&
			!values.contactPhone
		) {
			setLoading(false);
			openNotification(
				"warning",
				"Warning",
				"At least you must fill one value"
			);
		} else {
			updateOpportunity(values);
		}
	};

	const handleSearchAddressCancel = () => {
		setOpenSearchAddressModal(false);
	};

	const handleSearchAddressOpen = () => {
		setOpenSearchAddressModal(true);
	};

	const handleCallback = (childData) => {
		form.setFieldsValue({
			address: childData.address,
			latitude: childData.destLat,
			longitude: childData.destLng,
		});
		handleSearchAddressCancel();
	};

	const RenderSearchAddressModal = () => {
		return (
			<Modal
				title="Address"
				open={openSearchAddressModal}
				onCancel={handleSearchAddressCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={600}
			>
				<AddressGeolocation2
					search={true}
					parentCallback={handleCallback}
					single={false}
					currentLocation={false}
				/>
			</Modal>
		);
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				style={{ marginTop: "20px" }}
				initialValues={initialValues}
			>
				<Divider />
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item label="Address" name="address">
							<Input size="large" disabled />
						</Form.Item>
					</Col>
					{/* <Col span={24} lg={8} xl={6} style={{ alignSelf: "center" }}>
						<Button
							size="large"
							type="primary"
							htmlType="button"
							icon={<SearchOutlined />}
							onClick={handleSearchAddressOpen}
							style={{
								width: "100%",
							}}
							disabled={!isAdminUser}
						>
							Search Address
						</Button>
					</Col> */}
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item label="Contact Name" name="contactName">
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Contact Email"
							name="contactEmail"
							rules={[
								{
									type: "email",
									message: "The input is not valid E-mail",
								},
							]}
						>
							<Input size="large" readOnly={!isAdminUser} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Contact Phone"
							name="contactPhone"
							rules={[
								{
									len: 10,
									max: 10,
									message: "Phone number must have 10 numbers",
								},
							]}
						>
							<Input
								size="large"
								addonBefore={prefixSelector}
								readOnly={!isAdminUser}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Locator Person"
							name="locatorPerson"
							rules={[
								{
									required: true,
									message: "Locator name is required",
								},
							]}
						>
							<Input size="large" readOnly={!isAdminUser} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item label="Seller" name="seller">
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select seller"
								options={optionsSellers}
								loading={loadingSeller}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								disabled={!isAdminUser}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item label="Type of opportunity" name="opportunityType">
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select type"
								options={opportuniyTypeOptions}
								allowClear
							/>
						</Form.Item>
					</Col>
				</Row>

				<Divider />

				<Row gutter={16}>
					<Col
						span={24}
						sm={12}
						md={type === "localize" ? 8 : 4}
						xl={type === "localize" ? 6 : 4}
					>
						<Button
							type="primary"
							htmlType="submit"
							loading={loading}
							size="large"
							style={{
								width: "100%",
							}}
						>
							{type === "localize" ? "Contact Opportunity" : "Save"}
						</Button>
					</Col>
				</Row>
			</Form>
		);
	};

	return (
		<div>
			{contextHolder}
			{loading ? <CustomSpinner /> : <CustomForm />}
			<RenderSearchAddressModal />
		</div>
	);
};

export default OpportunityNew;
