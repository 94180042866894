import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	Table,
	Button,
	Statistic,
	Row,
	Col,
	notification,
} from "antd";
import Layout from "../layout/Layout";
import { CustomSearch, CustomSpinner } from "../utils/CustomComponents";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
const { Text } = Typography;

const LogisticsReportDetailed = ({ history }) => {
	const [loading, setLoading] = useState(true);
	const [originalList, setOriginalList] = useState([]);
	const [filterList, setFilterList] = useState([]);
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());
	const [selectOptions, setSelectOptions] = useState([]);
	const [pickerValue, setPickerValue] = useState([]);
	const refDownload = useRef();
	const [apiNotification, contextHolder] = notification.useNotification();
	// const [plants, setPlants] = useState([]);
	const [plant, setPlant] = useState("All");

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Quantity",
			dataIndex: "quantity",
			key: "quantity",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Material",
			dataIndex: "material",
			key: "material",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Plant",
			dataIndex: "plant",
			key: "plant",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Mine",
			dataIndex: "mine",
			key: "mine",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
	];

	const headers = () => {
		return columns.map((item) => {
			return {
				key: item.key,
				label: item.title,
			};
		});
	};

	useEffect(() => {
		getTripList(startDate, endDate, plant);
		// getPlants();
	}, []);

	// const getPlants = async () => {
	// 	setLoading(true);
	// 	const token = localStorage.getItem("token");
	// 	axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
	// 	const url = api + `/plant/list/options`;

	// 	try {
	// 		const { data } = await axios.get(url);
	// 		let list = [
	// 			{
	// 				value: "All",
	// 				label: "All",
	// 			},
	// 		];

	// 		if (data.success) {
	// 			data.payload.map((item) => {
	// 				list.push({
	// 					value: item._id,
	// 					label: item.name,
	// 				});
	// 			});
	// 			// setPlants(list);
	// 			setPlant("All");
	// 			getTripList(startDate, endDate, "All");
	// 		} else {
	// 			openNotification("error", "Error", data.message);
	// 			setLoading(false);
	// 		}
	// 	} catch (err) {
	// 		console.error(err.message);
	// 		openNotification("error", "Error", err.message);
	// 		return null;
	// 	}
	// };

	const getTripList = async (startDate, endDate, plant) => {
		setLoading(true);
		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const url =
			api +
			`/logisticstrip/list/dates?startDate=${start}&endDate=${end}&plant=${plant}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);

			let list = [];
			data.map((item, index) => {
				list.push({
					key: index,
					id: item._id,
					createdAt: item.createdAt,
					material: item.material,
					mine: item.mine,
					quantity: item.quantity,
					status: item.jobStatus || "Pouring",
					plant: item.plant.name,
				});
			});

			const accumByDate = list.reduce((accumulator, currentObject) => {
				const cDate = dayjs(currentObject.createdAt);

				const formattedDate = `${cDate.get("M") + 1}${cDate.get(
					"date"
				)}${cDate.get("y")}`;

				let material = "";
				switch (currentObject.material) {
					case "Sand":
						material = "S";
						break;
					case "Gravel":
						material = "G";

						break;
					case "Pea Gravel":
						material = "PG";

						break;

					default:
						break;
				}

				const plant = currentObject.plant === "Mission" ? "M" : "SB";
				const mine = currentObject.mine === "Mile 4" ? "M" : "T";

				const id = `${formattedDate}-${material}-${plant}-${mine}`;

				if (!accumulator[id]) {
					accumulator[id] = [];
				}

				accumulator[id].push(currentObject);

				return accumulator;
			}, {});

			const newListOfIDS = [];

			const newListGrouped = [];

			Object.keys(accumByDate).map((id) => {
				newListOfIDS.push({
					value: id,
					label: id,
				});

				newListGrouped.push({
					key: id,
					id: id,
					quantity: accumByDate[id].reduce(
						(accum, current) => accum + current.quantity,
						0
					),
					material: accumByDate[id][0].material,
					plant: accumByDate[id][0].plant,
					mine: accumByDate[id][0].mine,
					status: accumByDate[id][0].status,
				});
			});

			setSelectOptions(newListOfIDS);
			setOriginalList(newListGrouped);
			setFilterList(newListGrouped);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const RenderTables = () => {
		return (
			<Flex gap="medium" justify="center" vertical>
				<Table
					columns={columns}
					dataSource={filterList}
					pagination={{
						defaultPageSize: 10,
						showSizeChanger: true,
						pageSizeOptions: ["10", "30", "50", "100"],
					}}
					loading={loading}
					// onRow={(record, index) => ({
					// 	style: {
					// 		background: record.duplicated ? "#b9d3fa" : "white",
					// 	},
					// })}
				/>
			</Flex>
		);
	};

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
			getTripList(dates[0], dates[1], plant);
		}
	};

	const onChangePicker = (values) => {
		setPickerValue(values);
		if (values.length === 0) {
			setFilterList(originalList);
		} else {
			const filteredData = originalList.filter((item) =>
				values.includes(item.id)
			);
			setFilterList(filteredData);
		}
	};

	const RenderSearch = () => {
		return (
			<CustomSearch
				loading={loading}
				startDate={startDate}
				endDate={endDate}
				onRangeChange={onRangeChange}
				dateFormat={"MM/DD/YYYY"}
				isMulti={true}
				labelPicker={"ID"}
				pickerWidth={400}
				optionsPicker={selectOptions}
				onChangePicker={onChangePicker}
				pickerValue={pickerValue}
			/>
		);
	};

	const RenderDownloadReport = () => {
		return (
			<div>
				<CSVLink
					data={filterList}
					headers={headers()}
					filename={"57concrete-logistics-detailed.csv"}
					hidden
					ref={refDownload}
				>
					Download report
				</CSVLink>
				<Row gutter={16}>
					<Col span={24}>
						<Button
							type="primary"
							style={{ width: "100%" }}
							onClick={(e) => refDownload.current.link.click()}
							loading={loading}
							size="large"
						>
							Download Report
						</Button>
					</Col>
				</Row>
			</div>
		);
	};

	return (
		<Layout title="Logistics Detailed Report" description="Report">
			<Flex vertical gap={"large"}>
				<RenderSearch />
				<RenderDownloadReport />
				{loading ? <CustomSpinner /> : <RenderTables />}
				{contextHolder}
			</Flex>
		</Layout>
	);
};

export default LogisticsReportDetailed;
