import axios from "axios";
import React, { useEffect, useState } from "react";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	Button,
	Tag,
	notification,
	Checkbox,
	Row,
	Col,
} from "antd";
import Layout from "../layout/Layout";
import { CustomSpinner, CustomTable } from "../utils/CustomComponents";
import { EditFilled, CheckCircleOutlined } from "@ant-design/icons";
const { Text } = Typography;

const OpportunityDashboard = ({ history }) => {
	const [loading, setLoading] = useState(true);
	const [opportunityList, setOpportunityList] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	const updateOpportunityApproval = async (record) => {
		const opportunity = {
			approved: true,
			status: "Negotiation",
		};

		try {
			const url = api + "/opportunity/update/" + record.id;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = {
				Authorization: `Bearer ${token}`,
			};
			const { data } = await axios.patch(url, opportunity);
			if (data.success) {
				const newData = [...opportunityList];
				const index = newData.findIndex((item) => record.key === item.key);
				newData[index]["approved"] = true;
				newData[index]["status"] = "Negotiation";
				setOpportunityList(newData);

				openNotification("success", "Success", "Opportunity approved");
			} else {
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			openNotification(
				"error",
				"Error",
				"Error while trying to update opportunity"
			);
			console.error(err.message);
			return null;
		}
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "",
			dataIndex: "edit",
			width: 100,
			render: (_, record) => {
				return (
					<Row gutter={16}>
						<Button
							type="primary"
							icon={<EditFilled twoToneColor={"white"} />}
							onClick={(e) => history.push(`/opportunity/update/${record.id}`)}
						/>
						&nbsp;
						{}
						{(localStorage.getItem("email") === "eliud@gmail.com" ||
							localStorage.getItem("email") === "ec@57concrete.com" ||
							localStorage.getItem("email") === "bp@57concrete.com" ||
							localStorage.getItem("email") === "mgr@57concrete.com" ||
							localStorage.getItem("email") === "jh@57concrete.com" ||
							localStorage.getItem("email") === "rl@57concrete.com") &&
							record.status === "Contact" &&
							!record.approved &&
							record.requestApproval && (
								<Button
									type="primary"
									icon={<CheckCircleOutlined twoToneColor={"white"} />}
									onClick={(e) => {
										updateOpportunityApproval(record);
									}}
								/>
							)}
					</Row>
				);
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (value) => {
				let color = "default";
				switch (value) {
					case "New":
						color = "blue";
						break;
					case "Localize":
						color = "gold";
						break;
					case "Contact":
						color = "cyan";
						break;
					case "Negotiation":
						color = "magenta";
						break;
					case "Closed":
						color = "green";
						break;
					case "Lost":
						color = "error";
						break;

					default:
						break;
				}
				return <Tag color={color}>{value}</Tag>;
			},
		},
		{
			title: "Locator Person",
			dataIndex: "locatorPerson",
			key: "locatorPerson",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Location Address",
			dataIndex: "address",
			key: "address",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Contact Name",
			dataIndex: "contactName",
			key: "contactName",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Contact Email",
			dataIndex: "contactEmail",
			key: "contactEmail",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Contact Phone",
			dataIndex: "contactPhone",
			key: "contactPhone",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Seller",
			dataIndex: "seller",
			key: "seller",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Type",
			dataIndex: "opportunityType",
			key: "opportunityType",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Approved",
			dataIndex: "approved",
			key: "approved",
			render: (value) => {
				return <Checkbox checked={value} />;
			},
		},
	];

	useEffect(() => {
		getOpportunityList();
	}, []);

	const getOpportunityList = async () => {
		setLoading(true);

		let url = "";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		if (
			localStorage.getItem("email") === "eliud@gmail.com" ||
			localStorage.getItem("email") === "ec@57concrete.com" ||
			localStorage.getItem("email") === "bp@57concrete.com" ||
			localStorage.getItem("email") === "mgr@57concrete.com" ||
			localStorage.getItem("email") === "jh@57concrete.com" ||
			localStorage.getItem("email") === "rl@57concrete.com"
		) {
			url = api + `/opportunity/list/admin`;
		} else if (localStorage.getItem("role") === "Seller") {
			let userId = localStorage.getItem("userId");
			url = api + `/opportunity/list/seller/${userId}`;
		}

		try {
			const { data } = await axios.get(url);
			let list = [];
			if (data.success) {
				data.payload.map((item, index) => {
					list.push({
						key: index,
						id: item._id,
						status: item.status,
						address: item.address || "-",
						contactName: item.contactName || "-",
						contactEmail: item.contactEmail || "-",
						contactPhone: item.contactPhone || "-",
						locatorPerson: item.locatorPerson || "-",
						seller: item.seller ? item.seller.name : "Not assigned",
						approved: item.approved,
						requestApproval: item.requestApproval,
						opportunityType: item.opportunityType || "-",
					});
				});
				setOpportunityList(list);
			} else {
				openNotification("error", "Error", data.message);
			}

			setLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const onClickNewOpportunity = () => {
		history.push("/opportunity/new");
	};

	const onClickQuandrantsOpportunityPin = () => {
		history.push("/opportunity/quadrants/pinmap");
	};

	const onClickQuandrantsOpportunityHeat = () => {
		history.push("/opportunity/quadrants/heatmap");
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Row gutter={[16, 8]}>
					<Col span={24} xs={12} md={8} xl={4} xxl={3}>
						<Button
							type="primary"
							style={{ width: "100%" }}
							onClick={onClickNewOpportunity}
							size="large"
							loading={loading}
						>
							New opportunity
						</Button>
					</Col>
					<Col span={24} xs={12} md={8} xl={4} xxl={3}>
						<Button
							type="primary"
							style={{ width: "100%" }}
							onClick={onClickQuandrantsOpportunityPin}
							size="large"
							loading={loading}
						>
							Quadrants Pins Map
						</Button>
					</Col>
					<Col span={24} xs={12} md={8} xl={4} xxl={3}>
						<Button
							type="primary"
							style={{ width: "100%" }}
							onClick={onClickQuandrantsOpportunityHeat}
							size="large"
							loading={loading}
						>
							Quadrants Heat Map
						</Button>
					</Col>
				</Row>
				&nbsp;
				<CustomTable columns={columns} data={opportunityList} />
			</Flex>
		);
	};

	return (
		<Layout title="Opportunity Dashboard" description="Dashboard">
			{contextHolder}
			<Flex vertical gap={"large"}>
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default OpportunityDashboard;
