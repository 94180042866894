import React, {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { io } from "socket.io-client";
import { productionUrl } from "../components/utils/Api";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
	const [notificationCount, setNotificationCount] = useState(0);
	const [notifications, setNotifications] = useState([]);

	return (
		<NotificationContext.Provider
			value={{
				notificationCount,
				setNotificationCount,
				notifications,
				setNotifications,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
};

export default NotificationContext;

const SocketContext = createContext();

// SocketProvider component to wrap your app
export const SocketProvider = ({ children }) => {
	const socketRef = useRef(null);

	useEffect(() => {
		if (!localStorage.getItem("socketId") && localStorage.getItem("userId")) {
			if (!socketRef.current) {
				socketRef.current = io(productionUrl); // Your server URL here
			}

			const socket = socketRef.current;

			// Log when socket connects
			socket.on("connect", () => {
				localStorage.setItem("socketId", socket.id);
				socket.emit("register", localStorage.getItem("userId"));
			});

			// Cleanup on unmount
			return () => {
				if (!localStorage.getItem("socketId")) {
					socket.disconnect();
				}
			};
		}
	}, [localStorage.getItem("userId")]);

	const disconnectSocket = () => {
		if (socketRef.current) {
			socketRef.current.disconnect();
			socketRef.current.off("receive-notification");
		} else {
			const socket = io(productionUrl);
			socket.emit("remove-socket", localStorage.getItem("socketId"));
		}
	};

	return (
		<SocketContext.Provider
			value={{ socket: socketRef.current, disconnectSocket }}
		>
			{children}
		</SocketContext.Provider>
	);
};

export const useSocket = () => {
	return useContext(SocketContext);
};
