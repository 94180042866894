import React, { useEffect, useState } from "react";
import axios from "axios";
import { api } from "../utils/Api";
import { Typography, Flex, notification, Row, Col, Descriptions } from "antd";
import Layout from "../layout/Layout";
import { CustomSearch, CustomSpinner } from "../utils/CustomComponents";
import dayjs from "dayjs";

const { Text } = Typography;

const DirectorsManagement = ({ history }) => {
	const [loading, setLoading] = useState(false);
	const [plants, setPlants] = useState([]);
	const [plant, setPlant] = useState("All");
	const [logistics, setLogistics] = useState({});
	const [operations, setOperations] = useState({});
	const [aggregates, setAggregates] = useState([]);
	const [production, setProduction] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getPlants();
	}, []);

	const getJobs = async (startDate, endDate, plant) => {
		setLoading(true);
		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url =
			api +
			`/job/list/datetime?startDate=${start}&endDate=${end}&plant=${plant}`;
		const url2 = api + `/truck/list/plant/${plant}`;
		const url3 =
			api +
			`/logisticstrip/list/dates2?startDate=${start}&endDate=${end}&plant=${plant}`;
		const url4 =
			api +
			`/plantstartup/check/last2?startDate=${start}&endDate=${end}&plant=${plant}`;

		try {
			const jobs = await axios.get(url);
			const trucks = await axios.get(url2);
			const logistic = await axios.get(url3);
			const plantStartup = await axios.get(url4);

			if (
				jobs.data.success &&
				trucks.data.success &&
				logistic.data.success &&
				plantStartup.data.success
			) {
				const payloadJob = jobs.data.payload;
				const payloadTruck = trucks.data.payload;
				const payloadLogistic = logistic.data.payload;
				const payloadPlantStartup = plantStartup.data.payload;

				const jobsFiltered = payloadJob;
				// .filter(
				// 	(job) => job.customer.creditHold !== true
				// );

				const onTimeJobs = jobsFiltered.filter(
					(job) =>
						job.onTime === true &&
						(job.jobStatus === "ok" ||
							job.jobStatus === "Active" ||
							job.jobStatus === "Operations done (AR)")
				).length;

				const onNotTimeJobs = jobsFiltered.filter(
					(job) =>
						job.onTime === false &&
						(job.jobStatus === "ok" ||
							job.jobStatus === "Active" ||
							job.jobStatus === "Operations done (AR)")
				).length;

				const onHotShot = jobsFiltered.filter(
					(job) => job.hotShot === true
				).length;

				const percentajeOnTime =
					Math.round((onTimeJobs / (onTimeJobs + onNotTimeJobs)) * 100) || 0;

				const activeTrucks = payloadTruck.filter(
					(truck) => truck.active === true
				).length;

				const percentageCapacity = Math.round(
					(activeTrucks / payloadTruck.length) * 100
				);

				const averageDestinationTime = Math.round(
					jobsFiltered.reduce((accum, job) => {
						return (
							accum +
							(job.destinationTime
								? parseInt(job.destinationTime.split(" ")[0])
								: job.destinationMinutes)
						);
					}, 0) / jobsFiltered.length
				);

				setLogistics({
					jobsOnTime: onTimeJobs,
					jobsNotOnTime: onNotTimeJobs,
					totalJobs: onTimeJobs + onNotTimeJobs,
					percentajeOnTime: `${percentajeOnTime}%`,
					jobsOnHotShot: onHotShot,
					averageDestinationTime: `${averageDestinationTime} min`,
					percentageCapacity: `${percentageCapacity}%`,
				});

				const jobList = jobsFiltered.filter(
					(job) =>
						(job.jobStatus === "ok" ||
							job.jobStatus === "Active" ||
							job.jobStatus === "Operations done (AR)") &&
						job.customer.name !== "WASTE 57"
				);

				const jobList2 = jobsFiltered.filter(
					(job) => job.customer.name === "WASTE 57"
				);

				let loadsCount = 0;
				let yards = 0;
				let trucksObj = {};
				jobList.map((job) => {
					loadsCount += job.loads.length;

					job.loads.map((load) => {
						if (load.truckNumber in trucksObj) {
							trucksObj[load.truckNumber] = trucksObj[load.truckNumber] + 1;
						} else {
							trucksObj[load.truckNumber] = 1;
						}
						yards += load.yards;
					});
				});

				let tripsTruck = 0;
				let tripsNumber = 0;

				Object.entries(trucksObj).map(([key, value]) => {
					tripsTruck += 1;
					tripsNumber += value;
				});

				const averageYards = isNaN((yards / loadsCount).toFixed(2))
					? 0
					: (yards / loadsCount).toFixed(2);
				const averageTrips = isNaN((tripsNumber / tripsTruck).toFixed(2))
					? 0
					: (tripsNumber / tripsTruck).toFixed(2);
				const waste = jobList2.length;

				const averageTimeTrip =
					Math.round(
						jobList.reduce((accum, job) => {
							return (
								accum +
								(job.destinationTime
									? parseInt(job.destinationTime.split(" ")[0])
									: job.destinationMinutes)
							);
						}, 0) / jobList.length
					) || 0;

				setOperations({
					tripsMixer: loadsCount,
					averageTrips: averageTrips || 0,
					averageYards: averageYards || 0,
					averageTimeTrip: `${averageTimeTrip} min`,
					waste: waste,
				});

				let arrayProduction = [];

				jobsFiltered.map((job) => {
					if (job.aggregate) {
						job.aggregate.cogs.map((cog) => {
							if (
								arrayProduction.findIndex(
									(item) =>
										item.material === cog.cog.material &&
										item.unitOfConversion === cog.cog.unitOfConversion &&
										item.unitOfMeasurement === cog.cog.unitOfMeasurement
								) === -1
							) {
								arrayProduction.push({
									material: cog.cog.material,
									unitOfConversion: cog.cog.unitOfConversion,
									unitOfMeasurement: cog.cog.unitOfMeasurement,
									total: cog.quantity * job.loads.length,
								});
							} else {
								const index = arrayProduction.findIndex(
									(item) =>
										item.material === cog.cog.material &&
										item.unitOfConversion === cog.cog.unitOfConversion &&
										item.unitOfMeasurement === cog.cog.unitOfMeasurement
								);

								arrayProduction[index].total += cog.quantity * job.loads.length;
							}
						});
					}
				});

				arrayProduction.map((item) => {
					if (
						item.unitOfMeasurement === "lb" &&
						item.unitOfConversion === "lb"
					) {
						item.totalUnit = item.total;
					} else if (
						item.unitOfMeasurement === "ton" &&
						item.unitOfConversion === "lb"
					) {
						item.totalUnit = item.total / 2000;
					} else if (
						item.unitOfMeasurement === "gl" &&
						item.unitOfConversion === "gl"
					) {
						item.totalUnit = item.total;
					} else if (
						item.unitOfMeasurement === "gl" &&
						item.unitOfConversion === "oz"
					) {
						item.totalUnit = item.total / 128;
					}

					return item;
				});

				let newDescriptionProduction = [];
				arrayProduction.map((item, index) => {
					newDescriptionProduction.push({
						key: index,
						label: item.material,
						span: 4,
						children: `${item.totalUnit.toFixed(2)} ${item.unitOfMeasurement}`,
					});
				});

				let accumPlantStartup = 0;

				payloadPlantStartup.map((item) => {
					const date1 = new Date(item.startDate);
					const date2 = new Date(item.endDate);
					const differenceInMilliseconds = date2 - date1;
					const hoursDifference = differenceInMilliseconds / (1000 * 60 * 60);
					accumPlantStartup += hoursDifference;
				});

				newDescriptionProduction.push({
					key: "production",
					label: "Production Time Plant",
					span: 4,
					children: `${
						isNaN(accumPlantStartup / payloadPlantStartup.length)
							? "0"
							: (accumPlantStartup / payloadPlantStartup.length).toFixed(2)
					} hrs`,
				});

				setProduction(newDescriptionProduction);

				const tripsCount = payloadLogistic.length;
				let tripObj = {};
				payloadLogistic.map((trip) => {
					if (trip.truck.number in tripObj) {
						tripObj[trip.truck.number] = tripObj[trip.truck.number] + 1;
					} else {
						tripObj[trip.truck.number] = 1;
					}
				});

				let tripsAggregate = 0;
				let tripsAggregateNumber = 0;
				let listAggregates = [];

				Object.entries(tripObj).map(([key, value]) => {
					tripsAggregate += 1;
					tripsAggregateNumber += value;
				});

				listAggregates.push({
					key: "trips",
					label: "Trips",
					span: 4,
					children: `${tripsCount}`,
				});

				const groupedByMaterial = payloadLogistic.reduce((acc, trip) => {
					if (!acc[trip.material]) {
						acc[trip.material] = [];
					}
					acc[trip.material].push(trip);
					return acc;
				}, {});

				Object.entries(groupedByMaterial).map(([key, value]) => {
					let quantity = 0;
					value.map((item) => {
						quantity += item.quantity;
					});
					listAggregates.push({
						key: key,
						label: key,
						span: 4,
						children: `${quantity.toFixed(2)} ton`,
					});
				});

				listAggregates.push({
					key: "average",
					label: "Average Trips",
					span: 4,
					children: isNaN(tripsAggregateNumber / tripsAggregate)
						? 0
						: `${(tripsAggregateNumber / tripsAggregate).toFixed(2)}`,
				});

				setAggregates(listAggregates);
			} else {
				openNotification(
					"error",
					"Error",
					jobs.data.message ||
						trucks.data.message ||
						logistic.data.message ||
						plantStartup.data.message
				);
			}

			setLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const getPlants = async () => {
		setLoading(true);
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url = api + `/plant/list/options`;

		try {
			const { data } = await axios.get(url);
			let list = [{ value: "All", label: "All" }];

			if (data.success) {
				data.payload.map((item) => {
					list.push({
						value: item._id,
						label: item.name,
					});
				});
				setPlants(list);
				getJobs(startDate, endDate, plant);
			} else {
				openNotification("error", "Error", data.message);
				setLoading(false);
			}
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
			getJobs(dates[0], dates[1], plant);
		}
	};

	const RenderTables = () => {
		return plant !== "" ? (
			<Row gutter={[16, 16]}>
				<Col span={24} xs={24} md={12}>
					<Descriptions
						bordered
						title="Logistics"
						size="small"
						labelStyle={{
							width: "150px",
							backgroundColor: "#9ab1fc",
							fontWeight: "bold",
						}}
						items={[
							{
								key: "1",
								label: "Job On Time",
								span: 4,
								children: logistics.jobsOnTime,
							},
							{
								key: "2",
								label: "Job Not On Time",
								span: 4,
								children: logistics.jobsNotOnTime,
							},
							{
								key: "3",
								label: "Total",
								span: 4,
								children: logistics.totalJobs,
							},
							{
								key: "4",
								label: "% On Time",
								span: 4,
								children: logistics.percentajeOnTime,
							},
							{
								key: "5",
								label: "Average Time Job",
								span: 4,
								children: logistics.averageDestinationTime,
							},
							{
								key: "6",
								label: "Hot Shot",
								span: 4,
								children: logistics.jobsOnHotShot,
							},
							{
								key: "7",
								label: "% Distribution Capacity",
								span: 4,
								children: logistics.percentageCapacity,
							},
						]}
					/>
				</Col>
				<Col span={24} xs={24} md={12}>
					<Descriptions
						bordered
						title="Operations"
						size="small"
						labelStyle={{
							width: "150px",
							backgroundColor: "#9afc9d",
							fontWeight: "bold",
						}}
						items={[
							{
								key: "1",
								label: "Trips Mixer",
								span: 4,
								children: operations.tripsMixer,
							},
							{
								key: "2",
								label: "Average Trips",
								span: 4,
								children: operations.averageTrips,
							},
							{
								key: "3",
								label: "Average Yards",
								span: 4,
								children: operations.averageYards,
							},
							{
								key: "4",
								label: "Waste 57 Concrete",
								span: 4,
								children: operations.waste,
							},
							{
								key: "5",
								label: "Average Time Trip",
								span: 4,
								children: operations.averageTimeTrip,
							},
						]}
					/>
				</Col>
				<Col span={24} xs={24} md={12}>
					<Descriptions
						bordered
						title="Production"
						size="small"
						labelStyle={{
							width: "150px",
							backgroundColor: "#fcc49a",
							fontWeight: "bold",
						}}
						items={production}
					/>
				</Col>
				<Col span={24} xs={24} md={12}>
					<Descriptions
						bordered
						title="Aggregates"
						size="small"
						labelStyle={{
							width: "150px",
							backgroundColor: "#a79afc",
							fontWeight: "bold",
						}}
						items={aggregates}
					/>
				</Col>
			</Row>
		) : (
			<Row gutter={16} justify={"center"}>
				<Text disabled>Please select a plant</Text>
			</Row>
		);
	};

	const onChangePlant = (value) => {
		if (value) {
			setPlant(value);
			getJobs(startDate, endDate, value);
		}
	};

	const RenderSearch = () => {
		return (
			<CustomSearch
				loading={loading}
				startDate={startDate}
				endDate={endDate}
				onRangeChange={onRangeChange}
				dateFormat={"MM/DD/YYYY"}
				labelPicker={"Select plant"}
				pickerWidth={"200px"}
				optionsPicker={plants}
				onChangePicker={onChangePlant}
				pickerValue={plant}
			/>
		);
	};

	return (
		<Layout title="Directors Management" description="Report">
			{contextHolder}
			<Flex vertical gap={"large"}>
				<RenderSearch />
				{loading ? <CustomSpinner /> : <RenderTables />}
			</Flex>
		</Layout>
	);
};

export default DirectorsManagement;
