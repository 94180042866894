import React from "react";
import { Flex, Spin, Table, DatePicker, Input, Select } from "antd";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
const { Search } = Input;

const CustomTable = ({ columns, data, isScroll = true, loading = false }) => {
	return (
		<Table
			columns={columns}
			dataSource={data}
			pagination={{
				position: ["bottomRight"],
				pageSizeOptions: [10, 20, 50, 100],
				defaultPageSize: 10,
				showSizeChanger: true,
			}}
			scroll={{
				x: isScroll ? 1300 : true,
			}}
			style={{
				minWidth: "100%",
			}}
			loading={loading}
		/>
	);
};

const CustomPicker = ({
	label,
	onChange,
	options,
	loading,
	defaultValue,
	width,
	isMulti,
	value,
}) => {
	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	return (
		<Select
			showSearch
			placeholder={`Select ${label}`}
			optionFilterProp="children"
			size="large"
			onChange={onChange}
			filterOption={filterOption}
			options={options}
			disabled={loading}
			defaultValue={defaultValue}
			value={value}
			mode={isMulti ? "multiple" : undefined}
			style={{
				width: width,
			}}
		/>
	);
};

const CustomSearch = ({
	startDate,
	endDate,
	dateFormat,
	onRangeChange,
	onDateChange,
	onSearch,
	loading,
	labelPicker,
	optionsPicker,
	onChangePicker,
	defaultValuePicker,
	pickerWidth,
	isMulti = false,
	pickerValue,
	allowClearDate = true,
}) => {
	return (
		<Flex gap={"middle"}>
			{
				/**
				 *? Range Date Picker
				 */
				startDate && endDate && (
					<RangePicker
						defaultValue={[
							dayjs(startDate, dateFormat),
							dayjs(endDate, dateFormat),
						]}
						onChange={onRangeChange}
						format={dateFormat}
						size="large"
						disabled={loading}
						allowClear={false}
					/>
				)
			}
			{
				/**
				 *? Single Date Picker
				 */
				startDate && !endDate && (
					<DatePicker
						defaultValue={dayjs(startDate)}
						onChange={onDateChange}
						format={dateFormat}
						disabled={loading}
						allowClear={allowClearDate}
					/>
				)
			}
			{
				/**
				 *? Custom Picker
				 */
				optionsPicker && labelPicker && onChangePicker && (
					<CustomPicker
						label={labelPicker}
						options={optionsPicker}
						onChange={onChangePicker}
						loading={loading}
						defaultValue={defaultValuePicker}
						width={pickerWidth}
						isMulti={isMulti}
						value={pickerValue}
					/>
				)
			}
			{
				/**
				 *? Search input
				 */
				onSearch != null && (
					<Search
						placeholder="Search"
						enterButton
						onSearch={onSearch}
						allowClear
						style={{
							width: 400,
						}}
						size="large"
						loading={loading}
						disabled={loading}
					/>
				)
			}
		</Flex>
	);
};

const CustomSpinner = () => {
	return (
		<div
			style={{
				margin: "20px 0",
				marginBottom: "20px",
				padding: "50px 50px",
				textAlign: "center",
			}}
		>
			<Spin size="large" />
		</div>
	);
};

export { CustomTable, CustomSpinner, CustomSearch, CustomPicker };
