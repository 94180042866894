import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Routes";
import { ConfigProvider } from "antd";
import { NotificationProvider } from "./context/NotificationContext";

ReactDOM.render(
	<ConfigProvider
		theme={{
			token: {
				colorPrimary: "#233F86",
			},
		}}
	>
		<React.StrictMode>
			<NotificationProvider>
				<Routes />
			</NotificationProvider>
		</React.StrictMode>
	</ConfigProvider>,
	document.getElementById("root")
);
