import React, { Component } from "react";
import axios from "axios";
import Layout from "../layout/Layout";
import { api } from "../utils/Api";
// import Modal from "react-bootstrap/Modal";
// import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
// import { Flex } from "antd";
// import SendLogisticsTrip from "./SendLogisticsTrip";
import dayjs from "dayjs";

export default class LogisticsMaterialPDF extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModalEmail: false,
			showModalSms: false,
			pdf: "",
			loading: true,
			date: props.location.state.date,
			plant: props.location.state.plant,
			mine: props.location.state.mine,
		};
	}

	/**
	 * Get PDF Inspection
	 */
	getPDFLogisticsTrip = async () => {
		const date = dayjs(this.state.date);
		let dateString = `${date.year()}-${date.month() + 1}-${date.date()}`;
		const url =
			api +
			`/logisticstrip/pdfs?date=${dateString}&material=${this.props.match.params.id}&plant=${this.state.plant}&mine=${this.state.mine}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.post(url);
			this.setState({
				pdf: data,
				loading: false,
			});
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	/**
	 * Open and close email modal
	 * @param {Event} e event of the modal
	 */
	toggleModalEmail = (e) => {
		this.setState({
			showModalEmail: !this.state.showModalEmail,
		});
	};

	/**
	 * Open and close sms modal
	 * @param {Event} e event of the modal
	 */
	toggleModalSms = (e) => {
		this.setState({
			showModalSms: !this.state.showModalSms,
		});
	};

	componentDidMount() {
		this.getPDFLogisticsTrip();
	}

	render() {
		return (
			<Layout
				title={`Logistics Trip ${this.props.match.params.id} PDF`}
				description="Logistics Trip Report"
			>
				{/* <Flex>
					<Link
						onClick={this.toggleModalEmail}
						to="#"
						className="btn btn-primary"
					>
						Send logistics trip by email
					</Link>
					<Link
						onClick={this.toggleModalSms}
						to="#"
						className="btn btn-success"
						style={{ marginLeft: 20 }}
					>
						Send logistics trip by SMS
					</Link>
				</Flex> */}

				{/* <Modal
					show={this.state.showModalEmail}
					animation={false}
					onHide={this.toggleModalEmail}
				>
					<Modal.Header closeButton>
						<Modal.Title>Send Logistics Trip to:</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<SendLogisticsTrip
							logisticsId={this.props.match.params.id}
							pcallback={this.toggleModalEmail}
							isEmail={true}
						/>
					</Modal.Body>
				</Modal>
				<Modal
					show={this.state.showModalSms}
					animation={false}
					onHide={this.toggleModalSms}
				>
					<Modal.Header closeButton>
						<Modal.Title>Send Logistics Trip to:</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<SendLogisticsTrip
							logisticsId={this.props.match.params.id}
							pcallback={this.toggleModalSms}
							isEmail={false}
						/>
					</Modal.Body>
				</Modal> */}
				{this.state.loading ? (
					<Spinner />
				) : (
					<iframe src={this.state.pdf} width="95%" style={{ height: "90vh" }} />
				)}
			</Layout>
		);
	}
}
