import React, { useEffect, useState } from "react";
import axios from "axios";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	Button,
	notification,
	Row,
	Statistic,
	Col,
} from "antd";
import Layout from "../layout/Layout";
import {
	CustomSearch,
	CustomSpinner,
	CustomTable,
} from "../utils/CustomComponents";
import { EditFilled, FilePdfFilled } from "@ant-design/icons";

const { Text } = Typography;

const LienDashboard = ({ history }) => {
	const [loading, setLoading] = useState(false);
	const [opportunityList, setOpportunityList] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Property Owner's Name",
			dataIndex: "ownerName",
			key: "ownerName",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Property Owner's Address",
			dataIndex: "ownerAddress",
			key: "ownerAddress",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Contractor's Name",
			dataIndex: "contractorName",
			key: "contractorName",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Contractor's Address",
			dataIndex: "contractorAddress",
			key: "contractorAddress",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Company Name",
			dataIndex: "companyName",
			key: "companyName",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Company Address",
			dataIndex: "companyAddress",
			key: "companyAddress",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Property Address",
			dataIndex: "propertyAddress",
			key: "propertyAddress",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Deadline",
			dataIndex: "deadline",
			key: "deadline",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Description of Materials",
			dataIndex: "descriptionMaterials",
			key: "descriptionMaterials",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Date Delivered",
			dataIndex: "dateDelivered",
			key: "dateDelivered",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Price of Materials",
			dataIndex: "amountCharged",
			key: "amountCharged",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Princial Amount Owned",
			dataIndex: "principalAmountOwned",
			key: "principalAmountOwned",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Montly Interest Amount",
			dataIndex: "montlyInterestAmount",
			key: "montlyInterestAmount",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Total Amount Owned",
			dataIndex: "totalAmountOwned",
			key: "totalAmountOwned",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "",
			dataIndex: "edit",
			width: 100,
			render: (_, record) => {
				return (
					<Row gutter={16}>
						<Button
							type="primary"
							icon={<EditFilled twoToneColor={"white"} />}
							onClick={(e) => history.push(`/lien/update/${record.id}`)}
						/>
						&nbsp;
						<Button
							type="primary"
							danger
							icon={<FilePdfFilled twoToneColor={"white"} />}
							onClick={(e) => history.push(`/lien/pdf/${record.id}`)}
						/>
					</Row>
				);
			},
		},
	];

	useEffect(() => {
		getLoanList("");
	}, []);

	const getLoanList = async (term) => {
		setLoading(true);

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url = api + `/lien/list?term=${term}`;

		try {
			const { data } = await axios.get(url);
			let list = [];

			if (data.success) {
				data.payload.map((item, index) => {
					list.push({
						key: index,
						id: item._id,
						date: Intl.DateTimeFormat("en-US").format(new Date(item.createdAt)),
						ownerName: item.ownerName,
						ownerAddress: item.ownerAddress,
						contractorName: item.contractorName,
						contractorAddress: item.contractorAddress,
						companyName: item.companyName,
						companyAddress: item.companyAddress,
						propertyAddress: item.propertyAddress,
						deadline: Intl.DateTimeFormat("en-US").format(
							new Date(item.deadline)
						),
						descriptionMaterials: item.descriptionMaterials,
						dateDelivered: Intl.DateTimeFormat("en-US").format(
							new Date(item.dateDelivered)
						),
						amountCharged: item.amountCharged,
						principalAmountOwned: item.principalAmountOwned,
						montlyInterestAmount: item.montlyInterestAmount,
						totalAmountOwned: item.totalAmountOwned,
					});
				});
				setOpportunityList(list);
			} else {
				openNotification("error", "Error", data.message);
			}

			setLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const onClickNewOpportunity = () => {
		history.push("/lien/new");
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Row gap={[16, 16]}>
					<Col>
						<Button type="primary" onClick={onClickNewOpportunity} size="large">
							Add new lien
						</Button>
					</Col>
				</Row>

				<CustomTable columns={columns} data={opportunityList} isScroll={true} />
			</Flex>
		);
	};

	const onSearch = (value, _e, info) => {
		if (info?.source === "input") {
			getLoanList(value);
		}
	};

	const RenderSearch = () => {
		return <CustomSearch onSearch={onSearch} loading={loading} />;
	};

	return (
		<Layout title="Lien Dashboard" description="Dashboard">
			{contextHolder}
			<Flex vertical gap={"large"}>
				<RenderSearch />
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default LienDashboard;
