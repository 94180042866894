// import React, { Component } from 'react';
// import axios from 'axios'
// import { api } from '../utils/Api'
// import Layout from '../layout/Layout'
// import './GoogleMap.css'
// import Modal from 'react-bootstrap/Modal';
// import Spinner from 'react-bootstrap/Spinner'
// import SingleMap from './SingleMap';
// import MultipleMap from './MultipleMap';

// class MarkerInfoWindow2 extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       trucksPlantMission: [],
//       trucksPlantSanBenito: [],
//       trucksBackToPlant: [],
//       trucksInProgress: [],
//       center: {lat: 26.2787785, lng: -98.4021818},
//       googleApi: process.env.GOOGLE_MAP_API || "AIzaSyClyO950zwD8xsLYO7VHcyrnokEU6njbVs",
//       showModal: false,
//       selectedTrucks: [],
//       title: '',
//       loading: true,
//       showModalSingle: false,
//       titleSingle: '',
//       singleTruckId: '',
//       showModalMultiple: false,
//     };
//   }

//     getTruckList = async () => {
//     const url = api + '/trucklistmap'
//     const token = localStorage.getItem('token')
//     axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}

//     try {
//         const { data } = await axios.get(url)
//         let coordinatesTrucks = []
//         let centerCoordinates = {}
//         data.map((item, index) => {
//             if(item.latitude && item.longitude){
//                 if(index === 0){
//                     centerCoordinates = {lat: item.latitude, lng: item.longitude}
//                 }
//                 coordinatesTrucks.push({
//                     number: item.number,
//                     latitude: item.latitude,
//                     longitude: item.longitude,
//                     show: false,
//                     hover: false,
//                     direction: item.direction,
//                     plant: item.plant
//                 })
//             }
//         })

//         let inProgress = []
//         let backToPlant = []
//         let plantMission = []
//         let plantSanBenito = []

//         inProgress = data.filter(obj => obj.direction === -1 && obj.latitude && obj.longitude && obj.plant === -1)
//         backToPlant = data.filter(obj => obj.direction === 1 && obj.latitude && obj.longitude && obj.plant === -1)

//         // plantMission = data.filter(obj => obj.direction === 0 && obj.latitude && obj.longitude)
//         plantMission = data.filter(obj => obj.plant === 0)
//         plantSanBenito = data.filter(obj => obj.plant === 1)

//         this.setState({
//             center: centerCoordinates,
//             trucksInProgress: inProgress,
//             trucksBackToPlant: backToPlant,
//             trucksPlantMission: plantMission,
//             trucksPlantSanBenito: plantSanBenito,
//             loading: false
//         })

//     } catch (err) {
//         console.error(err)
//         return null
//     }
// }

//     componentDidMount() {
//         this.getTruckList()
//     }

//     onDisplaySigleMap = (truck) => {
//         this.setState({
//             titleSingle: `Truck ${truck.number}`,
//             singleTruckId: truck._id,
//             showModalSingle: true
//         })
//     }

//     backToPlantTable = () => {
//         return <div className='col-md-3'>
//             <table className='table table-hover'>
//                 <thead>
//                     <tr className="table-light">
//                         <th>Back to plant trucks</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {
//                         this.state.trucksBackToPlant.length !== 0 ? this.state.trucksBackToPlant.map((item) => (
//                             <tr onClick={e => this.onDisplaySigleMap(item)}>
//                                 <td>
//                                     {item.number}
//                                 </td>
//                             </tr>
//                         ))
//                         :
//                         <tr>
//                             <td>No trucks</td>
//                         </tr>
//                     }
//                 </tbody>
//             </table>
//         </div>
//     }

//     inProgressTable = () => {
//         return <div className='col-md-3'>
//             <table className='table table-hover'>
//                 <thead className='table table-hover'>
//                     <tr>
//                         <th>In Progress trucks</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {
//                         this.state.trucksInProgress.length !== 0 ? this.state.trucksInProgress.map((item) => (
//                             <tr onClick={e => this.onDisplaySigleMap(item)}>
//                                 <td>{item.number}</td>
//                             </tr>
//                         ))
//                         :
//                         <tr>
//                             <td>No trucks</td>
//                         </tr>
//                     }
//                 </tbody>
//             </table>
//         </div>
//     }

//     sanBenitoTable = () => {
//         return <div className='col-md-3'>
//             <table className='table table-hover'>
//                 <thead className='table table-hover'>
//                     <tr>
//                         <th>On San Benito plant trucks</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {
//                         this.state.trucksPlantSanBenito.length !== 0 ? this.state.trucksPlantSanBenito.map((item) => (
//                             <tr onClick={e => this.onDisplaySigleMap(item)}>
//                                 <td>{item.number}</td>
//                             </tr>
//                         ))
//                         :
//                         <tr>
//                             <td>No trucks</td>
//                         </tr>
//                     }
//                 </tbody>
//             </table>
//         </div>
//     }

//     missionTable = () => {
//         return <div className='col-md-3'>
//             <table className='table table-hover'>
//                 <thead className='table table-hover'>
//                     <tr>
//                         <th>On Mission plant trucks</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {
//                         this.state.trucksPlantMission.length !== 0 ? this.state.trucksPlantMission.map((item) => (
//                             <tr onClick={e => this.onDisplaySigleMap(item)}>
//                                 <td>{item.number}</td>
//                             </tr>
//                         ))
//                         :
//                         <tr>
//                             <td>No trucks</td>
//                         </tr>
//                     }
//                 </tbody>
//             </table>
//         </div>
//     }

//     renderSingleCount = (color, text, type) => {
//         return <div className='col-md-2' style={{
//             backgroundColor: 'white',
//             boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
//             padding: 10,
//             fontSize: 14,
//             borderRadius: 10,
//             cursor: 'pointer',
//         }}
//         onClick={() => this.toggleSelectedTrucks(type)}
//         >
//             <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
//                 <div style={{borderRadius: '50%', width: 10, height: 10, backgroundColor: `${color}`, marginRight: 10}} />
//                 <div style={{ fontWeight: 'bold'}}>{text}</div>
//             </div>
//         </div>
//     }

//     renderCountTrucks = () => {
//         return <div className='container row justify-content-around offset-md-1 mb-4 '>
//             {this.renderSingleCount('yellow', `Back to plant trucks - ${this.state.trucksBackToPlant.length}`,'backToPlant')}
//             {this.renderSingleCount('green', `In Progress trucks - ${this.state.trucksInProgress.length}`,'inProgress')}
//             {this.renderSingleCount('#ED1C24', `Trucks on Mission plant - ${this.state.trucksPlantMission.length}`,'missionPlant')}
//             {this.renderSingleCount('#233F86', `Trucks on San Benito plant - ${this.state.trucksPlantSanBenito.length}`,'sanBenitoPlant')}
//         </div>
//     }

//     toggleModal = () => {
//         this.setState({
//             showModal: !this.state.showModal
//         })
//     }

//     toggleModalSingle = () => {
//         this.setState({
//             showModalSingle: !this.state.showModalSingle
//         })
//     }

//     toggleModalMultiple = () => {
//         this.setState({
//             showModalMultiple: !this.state.showModalMultiple
//         })
//     }

//     toggleSelectedTrucks = (type) => {
//         switch (type) {
//             case 'backToPlant':
//                 this.setState({
//                     showModal: !this.state.showModal,
//                     selectedTrucks: this.state.trucksBackToPlant,
//                     title: 'Back to plant trucks'
//                 })
//                 break;
//             case 'inProgress':
//                 this.setState({
//                     showModal: !this.state.showModal,
//                     selectedTrucks: this.state.trucksInProgress,
//                     title: 'In Progress trucks'
//                 })
//                 break;
//             case 'missionPlant':
//                 this.setState({
//                     showModal: !this.state.showModal,
//                     selectedTrucks: this.state.trucksPlantMission,
//                     title: 'On Mission plant trucks'
//                 })
//                 break;
//             default:
//                 this.setState({
//                     showModal: !this.state.showModal,
//                     selectedTrucks: this.state.trucksPlantSanBenito,
//                     title: 'On San Benito plant trucks'
//                 })
//                 break;
//         }
//     }

//     displaySelectedTrucks = () =>{
//         return <div>
//             <table className='table'>
//                 <thead>
//                     <tr>
//                         <th>Number</th>
//                         <th>Latitude</th>
//                         <th>Longitude</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {
//                         this.state.selectedTrucks.length !== 0 ? this.state.selectedTrucks.map((item) => (
//                             <tr>
//                                 <td>{item.number}</td>
//                                 <td>{item.latitude}</td>
//                                 <td>{item.longitude}</td>
//                             </tr>
//                         ))
//                         :
//                         <tr>
//                             <td colSpan={3} className='text-center'>No trucks</td>
//                         </tr>
//                     }
//                 </tbody>
//             </table>
//         </div>
//     }

//     render() {

//     return (
//         <Layout
//             title='Trucks Map'
//             description='Display trucks position'
//             className=''
//         >

//         {
//             this.state.loading ?
//             <div className='d-flex justify-content-center'>
//                 <Spinner animation="border" role="status" size='lg' />
//             </div>
//             :
//             <div>
//                 <div className='container'>
//                     <div className='row justify-content-md-center'>
//                         <div className='col-md-auto'>
//                             <button className='btn btn-success' onClick={this.toggleModalMultiple}>Show map</button>
//                         </div>
//                     </div>
//                 </div>
//                 &nbsp;
//                 {this.renderCountTrucks()}
//                 <div className='container'>
//                     <div className='row justify-content-md-center'>
//                         {this.backToPlantTable()}
//                         {this.inProgressTable()}
//                         {this.missionTable()}
//                         {this.sanBenitoTable()}
//                     </div>
//                 </div>
//             </div>
//         }

//             <Modal
//                 show={this.state.showModal}
//                 animation={false}
//                 onHide={this.toggleModal}
//                 size='sm'
//                 centered
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title>{this.state.title}</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {this.displaySelectedTrucks()}
//                 </Modal.Body>
//             </Modal>

//             <Modal
//                 show={this.state.showModalSingle}
//                 animation={false}
//                 onHide={this.toggleModalSingle}
//                 size='md'
//                 centered
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title>{this.state.titleSingle}</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <SingleMap id={this.state.singleTruckId} />
//                 </Modal.Body>
//             </Modal>

//             <Modal
//                 show={this.state.showModalMultiple}
//                 animation={false}
//                 onHide={this.toggleModalMultiple}
//                 size='lg'
//                 centered
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title>Trucks Map</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <MultipleMap />
//                 </Modal.Body>
//             </Modal>

//         </Layout>
//     );
//   }
// }

// export default MarkerInfoWindow2;

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	Tag,
	Button,
	Card,
	Statistic,
	Row,
	Col,
	Modal,
	Skeleton,
} from "antd";
import CountUp from "react-countup";
import Layout from "../layout/Layout";
import { CustomSpinner, CustomTable } from "../utils/CustomComponents";
import {
	RollbackOutlined,
	PlayCircleOutlined,
	BankOutlined,
	GlobalOutlined,
	SyncOutlined,
} from "@ant-design/icons";
import SingleMap from "./SingleMap";
import MultipleMap from "./MultipleMap";

const { Text, Title } = Typography;

const formatter = (value) => <CountUp end={value} duration={1} separator="," />;

const TruckMap2 = () => {
	const [loading, setLoading] = useState(true);
	const [type, setType] = useState("backToPlant");
	const [title, setTitle] = useState("Back to Plant");
	const [trucksInProgress, setTrucksInProgress] = useState([]);
	const [trucksBackToPlant, setTrucksBackToPlant] = useState([]);
	const [trucksPlantMission, setTrucksPlantMission] = useState([]);
	const [trucksPlantSanBenito, setTrucksPlantSanBenito] = useState([]);
	const singleTruckId = useRef("");
	const singleTruck = useRef("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalOpen2, setIsModalOpen2] = useState(false);
	const [refresh, setRefresh] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};
	const hideModal = () => {
		setIsModalOpen(false);
	};
	const showModal2 = () => {
		setIsModalOpen2(true);
	};
	const hideModal2 = () => {
		setIsModalOpen2(false);
	};

	const onClickSingleTruck = (truck) => {
		singleTruckId.current = truck._id;
		singleTruck.current = truck.number;
		showModal();
	};

	/**
	 ** Options header (columns)
	 */
	const columnsPlant = [
		{
			title: "",
			dataIndex: "edit",
			width: 50,
			render: (_, record) => {
				return (
					<Button
						type="primary"
						icon={<GlobalOutlined twoToneColor={"white"} />}
						onClick={(e) => onClickSingleTruck(record)}
					/>
				);
			},
		},
		{
			title: "Truck Number",
			dataIndex: "number",
			key: "number",
			render: (value) => {
				return <Tag color={"cyan"}>{value}</Tag>;
			},
		},
		{
			title: "Latitude",
			dataIndex: "latitude",
			key: "latitude",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Longitude",
			dataIndex: "longitude",
			key: "longitude",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
	];

	const columnsDone = [
		{
			title: "",
			dataIndex: "edit",
			width: 50,
			render: (_, record) => {
				return (
					<Button
						type="primary"
						icon={<GlobalOutlined twoToneColor={"white"} />}
						onClick={(e) => onClickSingleTruck(record)}
					/>
				);
			},
		},
		{
			title: "Truck Number",
			dataIndex: "number",
			key: "number",
			render: (value) => {
				return <Tag color={"cyan"}>{value}</Tag>;
			},
		},
		{
			title: "Latitude",
			dataIndex: "latitude",
			key: "latitude",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Longitude",
			dataIndex: "longitude",
			key: "longitude",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Customer",
			dataIndex: "destinationCustomer",
			key: "destinationCustomer",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "From Address",
			dataIndex: "destinationAddress",
			key: "destinationAddress",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "To Plant",
			dataIndex: "destinationPlant",
			key: "destinationPlant",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
	];

	const columnsInProgress = [
		{
			title: "",
			dataIndex: "edit",
			width: 50,
			render: (_, record) => {
				return (
					<Button
						type="primary"
						icon={<GlobalOutlined twoToneColor={"white"} />}
						onClick={(e) => onClickSingleTruck(record)}
					/>
				);
			},
		},
		{
			title: "Truck Number",
			dataIndex: "number",
			key: "number",
			render: (value) => {
				return <Tag color={"cyan"}>{value}</Tag>;
			},
		},
		{
			title: "Latitude",
			dataIndex: "latitude",
			key: "latitude",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Longitude",
			dataIndex: "longitude",
			key: "longitude",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Customer",
			dataIndex: "destinationCustomer",
			key: "destinationCustomer",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "To Address",
			dataIndex: "destinationAddress",
			key: "destinationAddress",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "From Plant",
			dataIndex: "destinationPlant",
			key: "destinationPlant",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
	];

	useEffect(() => {
		getTruckList();
		// setLoading(false);
	}, [refresh]);

	const getTruckList = async () => {
		const url = api + "/trucklistmap2";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);

			let inProgress = [];
			let backToPlant = [];
			let plantMission = [];
			let plantSanBenito = [];

			inProgress = data.filter(
				(obj) =>
					obj.latitude &&
					obj.longitude &&
					((obj.direction === -1 && obj.plant === -1) ||
						(obj.direction === 0 && obj.plant === -1))
			);
			backToPlant = data.filter(
				(obj) =>
					obj.latitude &&
					obj.longitude &&
					obj.direction === 1 &&
					obj.plant === -1
			);

			plantMission = data.filter((obj) => obj.plant === 0);
			plantSanBenito = data.filter((obj) => obj.plant === 1);

			setTrucksInProgress(inProgress);
			setTrucksBackToPlant(backToPlant);
			setTrucksPlantMission(plantMission);
			setTrucksPlantSanBenito(plantSanBenito);

			setLoading(false);
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const onClickShowMap = () => {
		showModal2();
	};

	const RenderTitle = () => {
		return (
			<Row gap={"middle"}>
				<Col span={24}>
					<Title level={3}>{title}</Title>
				</Col>
				<Col span={24}>
					<Button
						type="primary"
						size="large"
						onClick={onClickShowMap}
						loading={loading}
					>{`Show Map ${title}`}</Button>
				</Col>
			</Row>
		);
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"}>
				{type === "backToPlant" && (
					<CustomTable columns={columnsDone} data={trucksBackToPlant} />
				)}
				{type === "inProgress" && (
					<CustomTable columns={columnsInProgress} data={trucksInProgress} />
				)}
				{type === "missionPlant" && (
					<CustomTable columns={columnsPlant} data={trucksPlantMission} />
				)}
				{type === "sanBenitoPlant" && (
					<CustomTable columns={columnsPlant} data={trucksPlantSanBenito} />
				)}
			</Flex>
		);
	};

	const onChangeType = (currentType) => {
		setType(currentType);
		if (currentType === "backToPlant") {
			setTitle("Back to Plant");
		} else if (currentType === "inProgress") {
			setTitle("In Progress");
		} else if (currentType === "missionPlant") {
			setTitle("On Mission Plant");
		} else if (currentType === "sanBenitoPlant") {
			setTitle("On San Benito Plant");
		}
	};

	const RenderTypes = () => {
		const gridStyle = {
			width: "25%",
			textAlign: "center",
		};
		return (
			<>
				{loading ? (
					<Skeleton active />
				) : (
					<Card style={{ cursor: "pointer" }}>
						<Card.Grid
							style={gridStyle}
							onClick={(e) => onChangeType("backToPlant")}
						>
							<Statistic
								title="Back to Plant"
								value={trucksBackToPlant.length}
								valueStyle={{
									color: "blue",
								}}
								prefix={<RollbackOutlined />}
								formatter={formatter}
							/>
						</Card.Grid>
						<Card.Grid
							style={gridStyle}
							onClick={(e) => onChangeType("inProgress")}
						>
							<Statistic
								title="In Progress"
								value={trucksInProgress.length}
								valueStyle={{
									color: "#3f8600",
								}}
								prefix={<PlayCircleOutlined />}
								formatter={formatter}
							/>
						</Card.Grid>
						<Card.Grid
							style={gridStyle}
							onClick={(e) => onChangeType("missionPlant")}
						>
							<Statistic
								title="On Mission Plant"
								value={trucksPlantMission.length}
								valueStyle={{
									color: "darkred",
								}}
								prefix={<BankOutlined />}
								formatter={formatter}
							/>
						</Card.Grid>
						<Card.Grid
							style={gridStyle}
							onClick={(e) => onChangeType("sanBenitoPlant")}
						>
							<Statistic
								title="On San Benito Plant"
								value={trucksPlantSanBenito.length}
								valueStyle={{
									color: "darkblue",
								}}
								prefix={<BankOutlined />}
								formatter={formatter}
							/>
						</Card.Grid>
					</Card>
				)}
			</>
		);
	};

	const RenderModals = () => {
		return (
			<>
				<Modal
					title={`Truck ${singleTruck.current} Map`}
					open={isModalOpen}
					onOk={hideModal}
					onCancel={hideModal}
					cancelButtonProps={{ style: { display: "none" } }}
					okButtonProps={{ style: { display: "none" } }}
				>
					<SingleMap id={singleTruckId.current} />
				</Modal>
				<Modal
					title={`Trucks Map`}
					open={isModalOpen2}
					onOk={hideModal2}
					onCancel={hideModal2}
					cancelButtonProps={{ style: { display: "none" } }}
					okButtonProps={{ style: { display: "none" } }}
				>
					<MultipleMap
						type={type}
						options={
							type === "backToPlant"
								? trucksBackToPlant
								: type === "inProgress"
								? trucksInProgress
								: type === "missionPlant"
								? trucksPlantMission
								: trucksPlantSanBenito
						}
					/>
				</Modal>
			</>
		);
	};

	const RenderRefreshButton = () => {
		return (
			<Button
				size="large"
				type="primary"
				danger
				style={{ width: 60, backgroundColor: "green" }}
				onClick={(e) => {
					setLoading(true);
					setRefresh(!refresh);
				}}
				icon={<SyncOutlined />}
				loading={loading}
				disabled={loading}
			></Button>
		);
	};

	return (
		<Layout title="Trucks Map" description="Display Truck Position">
			<Flex vertical gap={"large"}>
				<RenderRefreshButton />
				<RenderTypes />
				<RenderTitle />
				{loading ? <CustomSpinner /> : <RenderTable />}
				<RenderModals />
			</Flex>
		</Layout>
	);
};

export default TruckMap2;
