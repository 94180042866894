import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	Table,
	Statistic,
	Row,
	Col,
	Button,
	notification,
} from "antd";
import Layout from "../layout/Layout";
import { CustomSearch, CustomSpinner } from "../utils/CustomComponents";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
const { Text } = Typography;

const columnsCSV = [
	{ key: "date", label: "Date" },
	{ key: "invoice", label: "invoice" },
	{ key: "owner", label: "Owner" },
	{ key: "lender", label: "Lender" },
	{ key: "collateral", label: "Collateral" },
	{ key: "payment", label: "Total Amount" },
];

const LoanPaymentsDetailed = () => {
	const [loading, setLoading] = useState(true);
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());
	const [list, setList] = useState([]);
	const refDownload = useRef();
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Invoice",
			dataIndex: "invoice",
			key: "invoice",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Owner",
			dataIndex: "owner",
			key: "owner",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Lender",
			dataIndex: "lender",
			key: "lender",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Collateral",
			dataIndex: "collateral",
			key: "collateral",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Payment Amount",
			dataIndex: "payment",
			key: "payment",
			render: (value) => {
				return (
					<Statistic
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
	];

	useEffect(() => {
		getPayments("");
	}, [startDate, endDate]);

	const getPayments = async (term) => {
		setLoading(true);

		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const url =
			api +
			`/loan/get/payments/filtered?startDate=${start}&endDate=${end}&term=${term}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];

			if (data.success) {
				let filterdData = data.payload.filter(
					(val) => val.payments.length !== 0
				);

				filterdData.map((item) => {
					item.payments.map((payment) => {
						list.push({
							index: payment._id,
							key: payment._id,
							date: Intl.DateTimeFormat("en-US").format(
								new Date(payment.paymentDate)
							),
							currentDate: payment.paymentDate,
							invoice: payment.invoice || "",
							lender: item.lender,
							collateral: item.collateral,
							owner: item.owner,
							payment: payment.payment,
						});
					});
				});
				list = list.sort((a, b) => new Date(a.date) - new Date(b.date));
				setList(list);
				setLoading(false);
			} else {
				setLoading(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			openNotification("error", "Error", err.message);
			console.error(err.message);
			return null;
		}
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Table
					columns={columns}
					dataSource={list}
					pagination={{
						position: ["bottomRight"],
						pageSizeOptions: [10, 20, 50, 100],
						defaultPageSize: 100,
						showSizeChanger: true,
					}}
					scroll={{
						x: true,
					}}
					style={{
						minWidth: "100%",
					}}
					summary={(pageData) => {
						const total = pageData.reduce((accum, val) => {
							return accum + val.payment;
						}, 0);
						return (
							<Table.Summary fixed>
								<Table.Summary.Row>
									<Table.Summary.Cell index={0}></Table.Summary.Cell>
									<Table.Summary.Cell index={1}></Table.Summary.Cell>
									<Table.Summary.Cell index={2}></Table.Summary.Cell>
									<Table.Summary.Cell index={3}></Table.Summary.Cell>
									<Table.Summary.Cell index={4} align="right">
										<Text style={{ fontSize: 14 }} strong>
											{"Total"}
										</Text>
									</Table.Summary.Cell>
									<Table.Summary.Cell index={5}>
										<Statistic
											value={total}
											prefix={"$"}
											precision={2}
											valueStyle={{ fontSize: 14 }}
										/>
									</Table.Summary.Cell>
								</Table.Summary.Row>
							</Table.Summary>
						);
					}}
				/>
			</Flex>
		);
	};

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	};

	const onSearch = (value, _e, info) => {
		if (info?.source === "input") {
			getPayments(value);
		}
	};

	const RenderDates = () => {
		return (
			<CustomSearch
				loading={loading}
				startDate={startDate}
				endDate={endDate}
				dateFormat={"MM/DD/YYYY"}
				onRangeChange={onRangeChange}
				onSearch={onSearch}
			/>
		);
	};

	const RenderDownloadReport = () => {
		return (
			<div>
				<CSVLink
					data={list}
					headers={columnsCSV}
					filename={"57concrete-loan-payments-detailed.csv"}
					hidden
					ref={refDownload}
				>
					Download report
				</CSVLink>
				<Row gutter={16}>
					<Col span={24}>
						<Button
							type="primary"
							style={{ width: "100%" }}
							onClick={(e) => refDownload.current.link.click()}
							loading={loading}
							size="large"
						>
							Download Report
						</Button>
					</Col>
				</Row>
			</div>
		);
	};

	return (
		<Layout
			title="Loan Payments Detailed Report"
			description="Loan Report"
			type="medium"
		>
			<Flex vertical gap={"large"}>
				<RenderDates />
				<RenderDownloadReport />
				{loading ? <CustomSpinner /> : <RenderTable />}
				{contextHolder}
			</Flex>
		</Layout>
	);
};

export default LoanPaymentsDetailed;
