import React, { useState, useEffect } from "react";
import Spinner from "../layout/Spinner";
import { toThousands, porcentage, getDaysInMonth } from "../utils/Convert";
import { api } from "../utils/Api";
import axios from "axios";
import { Link } from "react-router-dom";

const SalesIniciative = ({ scope }) => {
	const [sales, setSales] = useState([]);
	const [summary, setSummary] = useState({});

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url = api + "/salesiniciative/all";

		try {
			let { data } = await axios.get(url);

			data = data.filter(
				(item) => item.sellerName.toUpperCase() !== "DIANA REYES"
			);

			if (scope === "commercial") {
				data = data.filter(
					(item) =>
						item.sellerName.toUpperCase() === "JUAN HERNANDEZ" ||
						item.sellerName.toUpperCase() === "CARLOS MANUEL CASTAÑOS" ||
						item.sellerName.toUpperCase() === "JUAN VALENZUELA" ||
						item.sellerName.toUpperCase() === "JAVIER BENAVIDES COMERCIAL" ||
						item.sellerName.toUpperCase() === "FERMIN AVENDAÑO"
				);
			} else if (scope === "residential") {
				data = data.filter(
					(item) =>
						item.sellerName.toUpperCase() === "CORPORATE" ||
						item.sellerName.toUpperCase() === "JESUS CANTU" ||
						item.sellerName.toUpperCase() === "EDGAR BRIONES" ||
						item.sellerName.toUpperCase() === "CRISTIAN TORRES" ||
						item.sellerName.toUpperCase() === "FERNANDO RAMIREZ" ||
						item.sellerName.toUpperCase() === "EDUARDO SALGADO" ||
						item.sellerName.toUpperCase() === "DAVID SANTAMARIA" ||
						item.sellerName.toUpperCase() === "ERIK PECINA" ||
						item.sellerName.toUpperCase() === "OSCAR VALADEZ" ||
						item.sellerName.toUpperCase() === "HECTOR PERALTA" ||
						item.sellerName.toUpperCase() === "RAMON LUNA"
				);
			}

			const sumPlan = data.reduce((acc, dato) => acc + dato.plan, 0);
			const sumReal = data.reduce((acc, dato) => acc + dato.real, 0);
			let promPercent = data.reduce((acc, dato) => acc + dato.percent, 0);
			const sumStatus = data.reduce((acc, dato) => acc + dato.statusYards, 0);
			let promStatus = data.reduce((acc, dato) => acc + dato.statusPercent, 0);
			promPercent = promPercent / data.length;
			promStatus = promStatus / data.length;

			const resumen = {
				plan: sumPlan,
				real: sumReal,
				percent: promPercent,
				statusYards: sumStatus,
				statusPercent: promStatus,
			};

			setSales(data);
			setSummary(resumen);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getReportLink = (scope) => {
		switch (scope) {
			case "loyalty":
				return "loyaltyaccomplishment";
			case "new":
			case "development":
				return "selleryardssold";
			default:
				return "selleraccomplishment";
		}
	};

	const DrawList = () => {
		return sales.map((item, i) => {
			const requerido = Math.ceil(item.required * 100);
			const porciento = Math.ceil(item.percent * 100);
			const estatYarda = toThousands(Math.ceil(item.statusYards));
			const liga = getReportLink(scope) + "/" + item.seller;

			return (
				<tr key={i}>
					<td>
						<Link to={liga}>{item.sellerName}</Link>
					</td>
					<td style={{ textAlign: "right" }}>
						{toThousands(item.plan.toFixed(2))}{" "}
					</td>
					<td style={{ textAlign: "right", fontWeight: "bold" }}>
						{toThousands(item.real.toFixed(2))}{" "}
					</td>
					<td style={{ textAlign: "right" }}>{porciento}% </td>
					<td style={{ textAlign: "right" }}>{requerido}%</td>
					<td style={{ textAlign: "right" }}>{estatYarda} </td>
					<td style={{ textAlign: "right" }}>
						{Math.ceil(item.statusPercent * 100)}%{" "}
					</td>
				</tr>
			);
		});
	};

	const RenderTable = () => (
		<div style={{ fontSize: "13px" }}>
			<table className="table">
				<thead>
					<tr>
						<th>Seller</th>
						<th style={{ textAlign: "right" }}>Plan</th>
						<th style={{ textAlign: "right" }}>Real</th>
						<th style={{ textAlign: "right" }}>%</th>
						<th style={{ textAlign: "right" }}>Required</th>
						<th style={{ textAlign: "right" }}>Status (YD3)</th>
						<th style={{ textAlign: "right" }}>Status (%)</th>
					</tr>
				</thead>
				<tbody>
					<DrawList />
					<tr>
						<td style={{ fontWeight: "bold" }}>TOTAL</td>
						<td style={{ textAlign: "right", fontWeight: "bold" }}>
							{toThousands(Math.ceil(summary.plan).toFixed(2))}
						</td>
						<td style={{ textAlign: "right", fontWeight: "bold" }}>
							{toThousands(Math.round(summary.real).toFixed(2))}
						</td>
						<td style={{ textAlign: "right", fontWeight: "bold" }}>
							{Math.ceil((summary.real / summary.plan) * 100)}%
						</td>
						<td></td>
						<td style={{ textAlign: "right", fontWeight: "bold" }}>
							{toThousands(Math.ceil(summary.statusYards))}
						</td>
						<td style={{ textAlign: "right", fontWeight: "bold" }}>
							{sales
								? Math.ceil(100 * (0.96 - summary.real / summary.plan))
								: 0}
							%
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);

	return (
		<div>
			<RenderTable />
		</div>
	);
};

export default SalesIniciative;
