import React, { memo } from "react";
import { Modal, Table, Button, Flex } from "antd";
import { CustomSearch, CustomSpinner } from "../utils/CustomComponents";

const ClaimJobModal = memo(
	({
		open,
		onCancel,
		jobList,
		loading,
		columns,
		onAccept,
		onRangeChange,
		onSearch,
		jobLoading,
		setJobSelected,
		startDate,
		endDate,
	}) => {
		const onClickRow = (selectedRowKeys, selectedRows) => {
			setJobSelected(selectedRows[0]);
		};

		return (
			<Modal
				title="Search Job"
				open={open}
				onCancel={onCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={1000}
			>
				<Flex vertical gap={"large"}>
					<CustomSearch
						onSearch={onSearch}
						loading={jobLoading}
						startDate={startDate}
						endDate={endDate}
						dateFormat={"MM/DD/YYYY"}
						onRangeChange={onRangeChange}
					/>
					{jobLoading ? (
						<CustomSpinner />
					) : (
						<Table
							columns={columns}
							dataSource={jobList}
							pagination={{
								position: ["bottomRight"],
								pageSizeOptions: [10, 20, 50, 100],
								defaultPageSize: 10,
								showSizeChanger: true,
							}}
							scroll={{
								x: true,
							}}
							style={{
								minWidth: "100%",
							}}
							loading={loading}
							rowSelection={{
								type: "radio",
								onChange: onClickRow,
							}}
						/>
					)}
					<Button
						size="large"
						type="primary"
						htmlType="button"
						onClick={onAccept}
					>
						Accept
					</Button>
				</Flex>
			</Modal>
		);
	}
);

export default ClaimJobModal;
