import React, { useEffect, useState, useRef } from "react";
import Layout from "../layout/Layout";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import { getForecastGroup } from "../../actions/forecast";
import { api } from "../utils/Api";
import axios from "axios";
import { groupSeller } from "../../actions/job";

const SellerAccomplishment = ({
	match,
	getForecastGroup,
	forecast: { forecasts, loading: forecastLoading },
	groupSeller,
	yards,
}) => {
	const sellerID = match.params.id;
	const today = new Date();
	const currentYear = today.getFullYear();

	const sellerForecasts = useRef();
	const customers = useRef([]);
	const sellerYards = useRef([]);
	const realYards = useRef([]);
	const DrawedList = useRef();

	const [sellerName, setSellerName] = useState("");
	const [titleLabel, setTitleLabel] = useState("Seller Accomplishment");
	const [loading, setLoading] = useState(true);
	const months = [
		"JAN",
		"FEB",
		"MAR",
		"APR",
		"MAY",
		"JUN",
		"JUL",
		"AUG",
		"SEP",
		"OCT",
		"NOV",
		"DEC",
	];

	useEffect(() => {
		getForecastGroup();
		getSellerInfo();
		groupSeller("true");
	}, []);

	useEffect(() => {
		if (forecasts) {
			sellerForecasts.current = forecasts.filter(
				(fc) => fc._id.seller === sellerID
			);
			customers.current = forecasts.map((f) => f._id.customerName);
		}
	}, [forecasts]);

	useEffect(() => {
		if (yards) {
			sellerYards.current = yards.filter(
				(y) => y._id.seller === sellerID && y._id.year === currentYear
			);
			groupYardsByCustomer();
		}
	}, [yards]);

	const getSellerInfo = async () => {
		const url = api + `/users/${sellerID}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			setSellerName(data.name);
			setTitleLabel(`Performance @${data.name}`);
			// sellerName.current = data.name
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	// Group real jobs by customer
	const groupYardsByCustomer = () => {
		let jobs = sellerYards.current;

		let auxList = []; // Structure -->  {customer: customerID, yards: [{period: "", realYards: ""}]}

		jobs.forEach((job) => {
			let currPeriod =
				`${job._id.year}-` +
				(job._id.month > 9 ? `${job._id.month}-01` : `0${job._id.month}-01`);

			if (auxList.some((j) => j.customer === job._id.customer)) {
				// If customer already in list, add object of {period, yards} to customer's yards

				let index = auxList.findIndex((l) => {
					return l.customer === job._id.customer;
				});

				let yardObj = { period: currPeriod, realYards: job.yards };

				auxList[index].yards.push(yardObj);
			} else {
				// If current customer not in list, create obj of customer and current {period, yards}

				let obj = {
					customer: job._id.customer,
					yards: [{ period: currPeriod, realYards: job.yards }],
				};

				auxList.push(obj);
			}
		});
		realYards.current = auxList;

		if (
			sellerForecasts.current.length > 0 &&
			Array.isArray(sellerForecasts.current[0].yards)
		) {
			createRealAndExpected();
		} else {
			setLoading(false);
		}
	};

	const createRealAndExpected = () => {
		const real = realYards.current;
		const expected = sellerForecasts.current;

		// structure --> [ { customer: customerID, customerName: customer, months: <customerMonthList> }, ]
		const result = [];

		// console.log(real)
		// console.log(expected)

		// Loop through expected
		expected.forEach((exp) => {
			// Loops through each forecast

			// Plan --> P
			// Real --> R

			let currCustomer = exp._id.customer;
			let currYards = exp.yards;

			let monthList = months.map((m) => {
				return { month: m, expYards: 0, realYards: 0 };
			}); // Month list for each customer

			currYards.forEach((yd) => {
				// Loops the forecast's yards

				const yardsExp = yd.yardsExpected;
				let month = yd.period;
				month = parseInt(month.substring(5, 7));

				monthList[month - 1].expYards += yardsExp;
			});

			let customerObj = {
				customer: exp._id.customer,
				customerName: exp._id.customerName,
				months: monthList,
			};

			result.push(customerObj);
		});

		// Loop through real, only using the already added customers in expected
		real.forEach((rl) => {
			const currCustomer = rl.customer;
			const currYards = rl.yards;

			if (!result.some((r) => r.customer === currCustomer)) {
				// not found currCustomer in list
				return;
			}

			let index = result.findIndex((l) => {
				return l.customer === currCustomer;
			});

			currYards.forEach((yd) => {
				let month = yd.period;
				month = parseInt(month.substring(5, 7));
				result[index].months[month - 1].realYards += yd.realYards;
			});
		});

		// console.log('result')
		// console.log(result)

		result.sort((a, b) => (a.customerName > b.customerName ? 1 : -1));

		DrawedList.current = result.map((item, i) => {
			let monthList = item.months;

			let displayList = [];

			monthList.forEach((ml) => {
				displayList.push(ml);
				displayList.push(ml);
			});

			let toggle = false;

			const monthListing = displayList.map((ml, j) => {
				toggle = toggle ? false : true;

				let label =
					ml.expYards > ml.realYards
						? "border text-center text-danger"
						: "border text-center text-success";

				return toggle ? (
					<td className="border text-center">{ml.expYards}</td>
				) : (
					<td className={label}>{ml.realYards}</td>
				);
			});

			// console.log(monthListing)

			return (
				<tr key={i}>
					<td> {item.customerName} </td>
					{monthListing}
				</tr>
			);
		});

		setLoading(false);
	};

	const RenderForecasts = () => (
		<div style={{ fontSize: "12px" }}>
			<table className="table">
				<thead>
					<tr>
						<td> </td>
						<td className="text-center" colSpan="2">
							<strong>JAN</strong>
						</td>
						<td className="text-center" colSpan="2">
							<strong>FEB</strong>
						</td>
						<td className="text-center" colSpan="2">
							<strong>MAR</strong>
						</td>
						<td className="text-center" colSpan="2">
							<strong>APR</strong>
						</td>
						<td className="text-center" colSpan="2">
							<strong>MAY</strong>
						</td>
						<td className="text-center" colSpan="2">
							<strong>JUN</strong>
						</td>
						<td className="text-center" colSpan="2">
							<strong>JUL</strong>
						</td>
						<td className="text-center" colSpan="2">
							<strong>AUG</strong>
						</td>
						<td className="text-center" colSpan="2">
							<strong>SEP</strong>
						</td>
						<td className="text-center" colSpan="2">
							<strong>OCT</strong>
						</td>
						<td className="text-center" colSpan="2">
							<strong>NOV</strong>
						</td>
						<td className="text-center" colSpan="2">
							<strong>DEC</strong>
						</td>
					</tr>
				</thead>
				<thead>
					<tr>
						<td> Client </td>
						<td className="text-center">PLAN</td>
						<td className="text-center">REAL</td>
						<td className="text-center">PLAN</td>
						<td className="text-center">REAL</td>
						<td className="text-center">PLAN</td>
						<td className="text-center">REAL</td>
						<td className="text-center">PLAN</td>
						<td className="text-center">REAL</td>
						<td className="text-center">PLAN</td>
						<td className="text-center">REAL</td>
						<td className="text-center">PLAN</td>
						<td className="text-center">REAL</td>
						<td className="text-center">PLAN</td>
						<td className="text-center">REAL</td>
						<td className="text-center">PLAN</td>
						<td className="text-center">REAL</td>
						<td className="text-center">PLAN</td>
						<td className="text-center">REAL</td>
						<td className="text-center">PLAN</td>
						<td className="text-center">REAL</td>
						<td className="text-center">PLAN</td>
						<td className="text-center">REAL</td>
						<td className="text-center">PLAN</td>
						<td className="text-center">REAL</td>
					</tr>
				</thead>
				<tbody>{DrawedList.current}</tbody>
			</table>
		</div>
	);

	return (
		<Layout title={titleLabel} description="">
			{forecastLoading || loading ? <Spinner /> : <RenderForecasts />}
		</Layout>
	);
};

const mapStateToProps = (state) => ({
	forecast: state.forecast,
	yards: state.job.yards,
});

export default connect(mapStateToProps, { getForecastGroup, groupSeller })(
	SellerAccomplishment
);
