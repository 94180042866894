import React, { useEffect, useState } from "react";
import axios from "axios";
import { api } from "../utils/Api";
import { Typography, Flex, notification, Statistic } from "antd";
import Layout from "../layout/Layout";
import {
	CustomSearch,
	CustomSpinner,
	CustomTable,
} from "../utils/CustomComponents";
import dayjs from "dayjs";

const { Text } = Typography;

const DieselLoadingReport = ({ history }) => {
	const [loading, setLoading] = useState(false);
	const [dieselLoadingList, setDieselLoadingList] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Driver",
			dataIndex: "driver",
			key: "driver",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Truck",
			dataIndex: "truck",
			key: "truck",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Miles",
			dataIndex: "miles",
			key: "miles",
			render: (value) => {
				return (
					<Statistic title="" value={value} valueStyle={{ fontSize: 14 }} />
				);
			},
		},
		{
			title: "Gallons",
			dataIndex: "gallons",
			key: "gallons",
			render: (value) => {
				return (
					<Statistic title="" value={value} valueStyle={{ fontSize: 14 }} />
				);
			},
		},
	];

	useEffect(() => {
		getDieselLoadingList("");
	}, []);

	const getDieselLoadingList = async (term) => {
		setLoading(true);
		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url =
			api +
			`/dieselloading/list?startDate=${start}&endDate=${end}&term=${term}`;

		try {
			const { data } = await axios.get(url);
			let list = [];

			if (data.success) {
				data.payload.map((item, index) => {
					list.push({
						key: index,
						id: item._id,
						driver: item.driver.name,
						truck: item.truck.number,
						miles: item.miles,
						gallons: item.gallons,
						date: Intl.DateTimeFormat("en-US").format(new Date(item.createdAt)),
					});
				});
				setDieselLoadingList(list);
			} else {
				openNotification("error", "Error", data.message);
			}

			setLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<CustomTable
					columns={columns}
					data={dieselLoadingList}
					isScroll={true}
				/>
			</Flex>
		);
	};

	const onSearch = (value, _e, info) => {
		if (info?.source === "input") {
			getDieselLoadingList(value);
		}
	};

	const RenderSearch = () => {
		return (
			<CustomSearch
				onSearch={onSearch}
				loading={loading}
				startDate={startDate}
				endDate={endDate}
				onRangeChange={onRangeChange}
				dateFormat={"MM/DD/YYYY"}
			/>
		);
	};

	return (
		<Layout title="Diesel Loading Report" description="Report">
			{contextHolder}
			<Flex vertical gap={"large"}>
				<RenderSearch />
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default DieselLoadingReport;
