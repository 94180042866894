import {
	REGISTER_SUCESS,
	REGISTER_FAIL,
	USER_LOADED,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
} from "../actions/types";

const initialState = {
	token: localStorage.getItem("token"),
	isAuthenticated: null,
	loading: true,
	user: null,
	role: null,
};

export default function authReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case REGISTER_SUCESS:
		case LOGIN_SUCCESS:
			localStorage.setItem("token", payload.token);
			localStorage.setItem("role", payload.usuario.role);
			localStorage.setItem("userId", payload.usuario.id);
			localStorage.setItem("userName", payload.usuario.name);
			localStorage.setItem("email", payload.usuario.email);
			localStorage.setItem(
				"jobHold",
				payload.usuario.jobHold ? payload.usuario.jobHold : false
			);
			localStorage.setItem(
				"corporateSales",
				payload.usuario.corporateSales ? payload.usuario.corporateSales : false
			);
			localStorage.setItem(
				"director",
				payload.usuario.director ? payload.usuario.director : false
			);
			localStorage.setItem(
				"manager",
				payload.usuario.manager ? payload.usuario.manager : false
			);
			if (payload.usuario.role === "Batch") {
				localStorage.setItem("subplant", payload.usuario.subplant);
				localStorage.setItem("plant", payload.usuario.plant);
			}
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
			};
		case REGISTER_FAIL:
		case AUTH_ERROR:
		case LOGIN_FAIL:
		case LOGOUT:
			localStorage.clear();

			return {
				...state,
				token: null,
				isAuthenticated: false,
				job: null,
				client: null,
				clients: [],
				jobs: [],
				truck: null,
				trucks: [],
				loading: false,
			};
		case USER_LOADED:
			return {
				...state,
				isAuthenticated: true,
				loading: false,
				user: payload,
			};
		default:
			return state;
	}
}
