import React, { useEffect, useState } from "react";
import axios from "axios";
import { api } from "../utils/Api";
import { Typography, Flex, Button, notification, Row, Col, Tag } from "antd";
import Layout from "../layout/Layout";
import {
	CustomSearch,
	CustomSpinner,
	CustomTable,
} from "../utils/CustomComponents";
import { EditFilled } from "@ant-design/icons";
import ClaimInfoTop from "./ClaimInfoTop";
import dayjs from "dayjs";

const { Text } = Typography;

const ClaimDashboard = ({ history }) => {
	const [loading, setLoading] = useState(false);
	const [claimList, setClaimList] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());
	const [term, setTerm] = useState("");
	const [changed, setChanged] = useState(false);

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Number",
			dataIndex: "number",
			key: "number",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Claim's Owner",
			dataIndex: "owner",
			key: "onwer",
			render: (value) => {
				return <Text>{value.name}</Text>;
			},
		},
		{
			title: "Area",
			dataIndex: "area",
			key: "area",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Customer's Name",
			dataIndex: "customer",
			key: "customer",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Users Involved",
			dataIndex: "usersInvolved",
			key: "usersInvolved",
			render: (value) => {
				return (
					<div>
						{value.map((item, index) => (
							<p key={index}>- {item.name}</p>
						))}
					</div>
				);
			},
		},
		{
			title: "Level",
			dataIndex: "level",
			key: "level",
			render: (value) => {
				let color = "default";
				let hours = "(0 Hrs)";
				switch (value) {
					case "Low":
						color = "yellow";
						hours = "(3 Days)";
						break;

					case "Medium":
						color = "green";
						hours = "(1-2 Days)";
						break;

					case "High":
						color = "red";
						hours = "(24 Hrs)";
						break;

					default:
						break;
				}
				return <Tag color={color}>{`${value} ${hours}`}</Tag>;
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (value) => {
				let color = "default";
				switch (value) {
					case "Open":
						color = "blue";
						break;

					case "In Progress":
						color = "green";
						break;

					case "Closed":
						color = "red";
						break;

					default:
						break;
				}
				return <Tag color={color}>{value}</Tag>;
			},
		},
		{
			title: "",
			dataIndex: "edit",
			width: 100,
			render: (_, record) => {
				let enabledUsers = [
					"ec@57concrete.com",
					"eliud@gmail.com",
					"bp@57concrete.com",
					"mgr@57concrete.com",
				];
				record.usersInvolved.map((item) => {
					enabledUsers.push(item.email);
				});
				enabledUsers.push(record.owner.email);

				const isEnabled = enabledUsers.includes(localStorage.getItem("email"));
				return (
					<Row gutter={16}>
						{isEnabled && (
							<Button
								type="primary"
								icon={<EditFilled twoToneColor={"white"} />}
								onClick={(e) => history.push(`/claim/update/${record.id}`)}
							/>
						)}
						&nbsp;
					</Row>
				);
			},
		},
	];

	useEffect(() => {
		getLoanList("");
	}, []);

	const getLoanList = async (term) => {
		setLoading(true);
		setTerm(term);
		setChanged((prev) => !prev);
		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url =
			api + `/claim/list?startDate=${start}&endDate=${end}&term=${term}`;

		try {
			const { data } = await axios.get(url);
			let list = [];

			if (data.success) {
				data.payload.map((item, index) => {
					list.push({
						key: index,
						id: item._id,
						number: item.number,
						date: Intl.DateTimeFormat("en-US").format(new Date(item.createdAt)),
						owner: item.owner,
						area: item.area,
						customer: item.customer.name,
						description: item.description,
						usersInvolved: item.usersInvolved,
						level: item.level,
						status: item.status,
					});
				});
				setClaimList(list);
			} else {
				openNotification("error", "Error", data.message);
			}

			setLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const onClickNewClaim = () => {
		history.push("/claim/new");
	};

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Row gap={[16, 16]}>
					<Col>
						<Button type="primary" onClick={onClickNewClaim} size="large">
							Add new claim
						</Button>
					</Col>
				</Row>

				<CustomTable columns={columns} data={claimList} isScroll={true} />
			</Flex>
		);
	};

	const onSearch = (value, _e, info) => {
		if (info?.source === "input") {
			getLoanList(value);
		}
	};

	const RenderSearch = () => {
		return (
			<CustomSearch
				onSearch={onSearch}
				loading={loading}
				startDate={startDate}
				endDate={endDate}
				onRangeChange={onRangeChange}
				dateFormat={"MM/DD/YYYY"}
			/>
		);
	};

	return (
		<Layout title="Claim Dashboard" description="Dashboard">
			{contextHolder}
			<Flex vertical gap={"large"}>
				<RenderSearch />
				<ClaimInfoTop
					startDate={startDate}
					endDate={endDate}
					term={term}
					changed={changed}
				/>
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default ClaimDashboard;
