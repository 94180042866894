import {
	Button,
	Col,
	DatePicker,
	Divider,
	Form,
	Input,
	InputNumber,
	Row,
	Space,
	message,
	notification,
} from "antd";
import React, { useRef, useState } from "react";
import Layout from "../layout/Layout";
import { api } from "../utils/Api";
import axios from "axios";
import dayjs from "dayjs";
import SignatureCanvas from "react-signature-canvas";

const NewLien = ({ history }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [apiNotification, contextHolder] = notification.useNotification();
	const signaturePadRef = useRef(null);

	const handleClear = () => {
		signaturePadRef.current.clear();
	};

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	const createLien = async (data) => {
		const lien = {
			...data,
		};

		try {
			let url = api + "/lien/create";

			const { data } = await axios.post(url, lien);
			if (data.success) {
				addNewImageSignature(data.payload._id);
			} else {
				openNotification("error", "Error", data.message);
				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			openNotification("error", "Error", "Error while trying to create lien");
			console.error(err.message);
			return null;
		}
	};

	const getCanvasWithWhiteBackground = (canvas) => {
		const newCanvas = document.createElement("canvas");
		const context = newCanvas.getContext("2d");

		// Set canvas dimensions
		newCanvas.width = canvas.width;
		newCanvas.height = canvas.height;

		// Fill the background with white
		context.fillStyle = "#fff";
		context.fillRect(0, 0, newCanvas.width, newCanvas.height);

		// Draw the signature on top
		context.drawImage(canvas, 0, 0);

		return newCanvas;
	};

	const addNewImageSignature = async (id) => {
		const formData = new FormData();

		const originalCanvas = signaturePadRef.current.getTrimmedCanvas();

		// Add a white background to the canvas
		const canvasWithWhiteBackground =
			getCanvasWithWhiteBackground(originalCanvas);

		// Convert the updated canvas to a data URL
		const signatureData = canvasWithWhiteBackground.toDataURL("image/png");

		// Convert Base64 to File
		const base64ToFile = (base64, filename) => {
			const byteString = atob(base64.split(",")[1]);
			const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
			const ab = new ArrayBuffer(byteString.length);
			const ia = new Uint8Array(ab);
			for (let i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}
			return new File([ab], filename, { type: mimeString });
		};

		const signatureFile = base64ToFile(signatureData, "signature.png");

		formData.append("signature", signatureFile);

		try {
			setLoading(true);
			const url = api + `/lien/add/signature/${id}`;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
			const config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};

			const { data } = await axios.post(url, formData, config);
			if (data.success) {
				openNotification("success", "Success", "Lien Created");
				handleClear();

				setTimeout(() => {
					setLoading(false);
					history.push("/lien/dashboard");
				}, 2000);
			} else {
				handleClear();

				setLoading(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			setLoading(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to add images signature"
			);
			console.error(err.message);
			return null;
		}
	};

	const onFinish = () => {
		if (signaturePadRef.current.isEmpty()) {
			message.error("Please provide a signature before submitting.");
			return;
		}

		const values = form.getFieldsValue();
		let finalValues = {
			...values,
			deadline: dayjs(values.deadline).toDate(),
			dateDelivered: dayjs(values.dateDelivered).toDate(),
		};
		createLien(finalValues);
	};

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				style={{ marginTop: "20px" }}
				initialValues={{
					amountCharged: 0,
					principalAmountOwned: 0,
					montlyInterestAmount: 0,
					totalAmountOwned: 0,
				}}
			>
				<Divider />
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Property Owner's Name"
							name="ownerName"
							rules={[
								{
									required: true,
									message: "Property Owner's Name is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Property Owner's Address"
							name="ownerAddress"
							rules={[
								{
									required: true,
									message: "Property Owner's Address is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Contractor's Name"
							name="contractorName"
							rules={[
								{
									required: true,
									message: "Contractor's Name is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Contractor's Address"
							name="contractorAddress"
							rules={[
								{
									required: true,
									message: "Contractor's Address is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Company Name"
							name="companyName"
							rules={[
								{
									required: true,
									message: "Company Name is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Company Address"
							name="companyAddress"
							rules={[
								{
									required: true,
									message: "Company Address is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Property Address"
							name="propertyAddress"
							rules={[
								{
									required: true,
									message: "Property Address is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Deadline"
							name="deadline"
							rules={[
								{
									required: true,
									message: "Deadline Address is required",
								},
							]}
						>
							<DatePicker
								size="large"
								style={{ width: "100%" }}
								format={"MM/DD/YYYY"}
								allowClear={false}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={24} sm={12} lg={8}>
						<Form.Item
							label="Description of Materials	"
							name="descriptionMaterials"
							rules={[
								{
									required: true,
									message: "Description of Materials is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={8}>
						<Form.Item
							label="Date Delivered"
							name="dateDelivered"
							rules={[
								{
									required: true,
									message: "Date Delivered is required",
								},
							]}
						>
							<DatePicker
								size="large"
								style={{ width: "100%" }}
								format={"MM/DD/YYYY"}
								allowClear={false}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={8}>
						<Form.Item
							label="Price of Materials"
							name="amountCharged"
							rules={[
								{
									required: true,
									message: "Price of Materials is required",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								step={"0.001"}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={24} sm={12} lg={8}>
						<Form.Item
							label="Princial Amount Owned"
							name="principalAmountOwned"
							rules={[
								{
									required: true,
									message: "Princial Amount Owned is required",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								step={"0.001"}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={8}>
						<Form.Item
							label="Montly Interest Amount"
							name="montlyInterestAmount"
							rules={[
								{
									required: true,
									message: "Montly Interest Amount is required",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								step={"0.001"}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
					<Col span={24} sm={12} lg={8}>
						<Form.Item
							label="Total Amount Owned"
							name="totalAmountOwned"
							rules={[
								{
									required: true,
									message: "Total Amount Owned is required",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								min={0}
								defaultValue={0}
								step={"0.001"}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Divider />

				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							label="Signor's Name"
							name="signorName"
							rules={[
								{
									required: true,
									message: "Signor's Name is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item label="Signature" required>
							<div
								style={{
									border: "1px solid #d9d9d9",
									padding: 10,
									borderRadius: 4,
								}}
							>
								<SignatureCanvas
									ref={signaturePadRef}
									penColor="black"
									backgroundColor="rgba(255,255,255,1)"
									canvasProps={{
										style: { width: "100%" },
										height: 400,
										className: "sigCanvas",
									}}
								/>
							</div>
							<Space style={{ marginTop: 10 }}>
								<Button type="default" htmlType="button" onClick={handleClear}>
									Clear Signature
								</Button>
							</Space>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={16}>
					<Col span={24} sm={12} md={4}>
						<Button
							type="primary"
							htmlType="submit"
							loading={loading}
							size="large"
							style={{
								width: "100%",
							}}
						>
							Create
						</Button>
					</Col>
				</Row>
			</Form>
		);
	};

	return (
		<Layout title="New Lien" description="Create new lien" type="medium">
			{contextHolder}
			<CustomForm />
		</Layout>
	);
};

export default NewLien;
